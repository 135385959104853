import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const BusinessLandingLayout = (props) => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/jquery-2.1.4.min.js"}
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/jquery.plugins.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/animations.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/scripts.js"}
        ></script>

        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/revolution.tools.min.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/business-landing/js/rs6.min.js"}
        ></script>
        <link rel='stylesheet' href='http://fonts.googleapis.com/css?family=Nunito+Sans:100,200,300,400,400italic,500,700,700italic,900' />
        <link rel='stylesheet' href='http://fonts.googleapis.com/css?family=Lato:100,200,300,400,400italic,500,700,700italic,900' />
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/global.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/el_demo.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/structure.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/agency4.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/corporation3.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/custom.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/rs6.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/business-landing/css/style-demo.css"}
        ></link>
      </Helmet>
      {React.cloneElement(props.children)}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(BusinessLandingLayout);
