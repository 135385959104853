import React, { useEffect, useMemo } from "react";
import { Notify } from "react-redux-notify";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import Sidebar from "./Sidebar";
import { fetchUserDetailsStart } from "../store/actions/UserAction";
import { connect } from "react-redux";
import FbChatAfterLogin from "../Helper/FbChatAfterLogin";
import { useLocation, useRouteMatch } from "react-router-dom";

const MainLayout = (props) => {
  const location = useRouteMatch();

  const diabledLayouts = useMemo(
    () => ["/tokens/create-token/confirmation/:token_unique"],
    []
  );

  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  if (diabledLayouts.includes(location.path)) {
    return (
      <>
        <Notify position="TopRight" />
        {props.children}
      </>
    );
  }

  if (!diabledLayouts.includes(location.path)) {
    return (
      <>
        <div className="wrapper">
          <Notify position="TopRight" />
          <HeaderIndex profile={props.profile} />
          <Sidebar profile={props.profile} />
          {props.children}
          <FooterIndex />
          <FbChatAfterLogin />
        </div>
      </>
    );
  }
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MainLayout);
