import {
  FETCH_FAQ_START,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAILURE,
  SAVE_SUPPORT_CONTACT_START,
  SAVE_SUPPORT_CONTACT_SUCCESS,
  SAVE_SUPPORT_CONTACT_FAILURE,
} from "./ActionConstant";

export function fetchFaqStart(data) {
  return {
    type: FETCH_FAQ_START,
    data,
  };
}

export function fetchFaqSuccess(data) {
  return {
    type: FETCH_FAQ_SUCCESS,
    data,
  };
}

export function fetchFaqFailure(data) {
  return {
    type: FETCH_FAQ_FAILURE,
    data,
  };
}

export function saveSupportContactStart(data) {
  return {
    type: SAVE_SUPPORT_CONTACT_START,
    data,
  };
}

export function saveSupportContactSuccess(data) {
  return {
    type: SAVE_SUPPORT_CONTACT_SUCCESS,
    data,
  };
}

export function saveSupportContactFailure(error) {
  return {
    type: SAVE_SUPPORT_CONTACT_FAILURE,
    error,
  };
}
