import React,{useState} from 'react'
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import {Link} from 'react-router-dom'

const usePopoverStyle = makeStyles((theme) => ({
  MuiPopover : {
    '& .MuiPaper-root' : {
      backgroundColor: "#1f1f1f",
      boxShadow : "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);",
      padding: "1em",
      overflow: 'visible',
    }
  }
}));


const AdditionalInfo = (props) => {
  const classes = usePopoverStyle()

  const [eyeopen, setEyeOpen] = useState(false);

  const handleEyeTooltipClose = () => {
    setEyeOpen(false);
  };

  const handleClick = (event) => {
    setEyeOpen(event.currentTarget);
  };

  const open = Boolean(eyeopen);
  const id = eyeopen ? 'simple-popover' : undefined;

  return (
    <>
       <div>
           <Button aria-describedby={id}  className="nostyle-btn" onClick={handleClick}>                    
            <span className="transaction-eye text-muted"><i class="far fa-eye"></i></span>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={eyeopen}
            onClose={handleEyeTooltipClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            className={classes.MuiPopover}
          >
             <div className="additional-details-popover">
               <h5 className="heading mb-3">Additional Info</h5>
               <div className="status-wrapper">
                  <h6 className="heading">Status :</h6>
                  {props.transactionData.confirmations != "" ? (
                    <>
                      <div classNmae="success mb-3">
                        <i className="far fa-check-circle text-success"></i>
                        <span className="success-text ml-2 text-success">Success</span>
                        <span className="ml-2">({props.transactionData.confirmations} confirmation)</span>
                      </div>
                    </>
                  ): (
                    <div classNmae="failed">
                    <i classNmae="fas fa-times-circle  text-danger"></i>
                    <span className="success-text ml-2 text-danger">Failed</span>
                  </div>
                  )}
               </div>
               <hr />
               <div className="token-info my-3">
                <h6 className="heading">Token Transfer :</h6>
                  <div className="d-flex">
                    <i class="fab fa-ethereum font-crypto-trans"></i>
                    <p className="mb-0 ml-2 text-primary">{props.transactionData.value}{props.transactionData.tokenSymbol}</p>
                    <p className="mb-0 ml-2">ETH</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6 tranaction-address mt-1 ">
                      <div className="tranaction-address d-flex ">
                        <span>From</span>
                        <span className="ml-3 tranaction-address-text">{props.transactionData.from}</span>
                      </div>
                    </div>  
                    <div className="col-md-6 tranaction-address mt-1">
                    <div className="tranaction-address d-flex ">
                        <span>to</span>
                        <span className="ml-3 tranaction-address-text">{props.transactionData.to}</span>
                      </div>
                    </div>  
                  </div>
               </div>
               <hr />
               <div className="fee-info">
                 <h6 className="heading">Transaction fee :</h6>
                 <div className=" d-flex ">
                    <span>{props.transactionData.gasPrice}</span>
                    {/* <span className="ml-2">Ether</span>
                    <span className="ml-2">($9.11)</span> */}
                  </div>
               </div>
               <hr />
               <div className="gas-info">
                 <h6 className="heading">Gas Info :</h6>
                 <div className=" d-flex ">
                    <p className="mb-0">{props.transactionData.gasUsed} gas used from {props.transactionData.gas} gas limit</p>
                  </div>
               </div>
               <hr />
               <div className="nounce-info">
                 <h6 className="heading">Nounce Details :</h6>
                 <div className=" d-flex ">
                    <p className="mb-0">{props.transactionData.nonce} nonce</p>
                  </div>
               </div>
               <hr />
               <Link to={{
                pathname : "/transcations-details" ,
                state : {
                  transactionDetail : props.transactionData
                }
                } 
               }className="text-primary">See more details <i class="fas fa-arrow-right"></i></Link>
             </div>
            </Popover>
       </div>
    </>
  )
}

export default AdditionalInfo
