import React, { useEffect, useState, useContext } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchSingleTokenStart,
  saveTokenContractAddrStart,
} from "../store/actions/TokenAction";
import { Redirect, useParams } from "react-router";
import Web3 from "web3";
import PolygonToken from "../../abis/PolygonToken.json";
import BinanceToken from "../../abis/BinanceToken.json";
import EtherToken from "../../abis/EtherToken.json";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { SupportedChainId, CHAIN_INFO } from "../auth/authprovider/chains";
import { useWeb3React } from "@web3-react/core";
import { authContext } from "../auth/authprovider/AuthProvider";
import ConnectWalletModal from "../auth/ConnectWalletModal";
import axios from "axios";

const DeployContract = (props) => {
  const { token_unique } = useParams();
  const context = useWeb3React();
  const { chainId, account, active } = context;
  const { auth, setAuth, hanldeLogout } = useContext(authContext);

  useEffect(() => {
    props.dispatch(
      fetchSingleTokenStart({ crypto_token_unique_id: token_unique })
    );
  }, []);

  const etherNetID = 1; // mainnet-  1 // test - 1

  const binanceNetID = SupportedChainId.BINANCE; // mainnet - 56 // test = 97

  const polygonNetID = SupportedChainId.POLYGON; // mainnet - 137 // test = 80001

  const velasNetID = SupportedChainId.VELAS;

  const [connectMetaMask, setConnectMetaMask] = useState(false);

  const [walletAddress, setWalletAddress] = useState("");

  const [loadinBlockchain, setLoadingBlockchain] = useState(true);

  const [loading, setLoading] = useState(true);

  //const [account, setAccount] = useState("");

  const [ethBalance, setEthBalance] = useState("0");

  const [token, setToken] = useState("");

  const [crytoSymbol, setCrytoSymbol] = useState("ETH");

  const [gasEstimation, setGasEstimation] = useState("0");

  const [metamaskNetStatus, setMetamaskNetStatus] = useState("");

  const [deployContractStatus, setDeployContractStatus] = useState(false);

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [deployContractButtonContent, setDeployContractButtonContent] =
    useState("");

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [currentChainId, setCurrentChainId] = useState(SupportedChainId.POLYGON);

  const [priceUSD, setPriceUsD] = useState("0");

  useEffect(() => {
    if (deployContractButtonContent !== "") {
      window.onbeforeunload = function () {
        console.log("refreshed..!!");
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [deployContractButtonContent]);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setLoadingBlockchain(false);
      setConnectMetaMask(true);
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setLoadingBlockchain(false);
      setConnectMetaMask(true);
      loadBlockchainData();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const findNet = async () => {
    switch (chainId) {
      case etherNetID:
        setMetamaskNetStatus("ether");
        return "ETH";
        break;
      case binanceNetID:
        setMetamaskNetStatus("bnb");
        return "BNB";
        break;
      case polygonNetID:
        setMetamaskNetStatus("polygon");
        return "MATIC";
        break;
      default:
        return "";
        break;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    //setAccount(accounts[0]);

    const ethBalance = await web3.eth.getBalance(accounts[0]);
    setEthBalance(ethBalance);

    findNet().then((val) => setCrytoSymbol(val));
    setLoading(false);

    estimateGasPrice();
  };

  const estimateGasPrice = async () => {
    const web3 = window.web3;
    // let gasPr = await web3.eth.estimateGas({ data: EtherToken.bytecode });

    // web3.eth.getGasPrice().then((result) => {
    //   setGasEstimation(web3.utils.fromWei(result, "ether"));
    // });

    const accounts = await web3.eth.getAccounts();
    const erc20Token = new web3.eth.Contract(EtherToken.abi);
    const max_token_supply =
      props.tokenDetails.data.crypto_token.max_token_supply.toString();
    try {
      console.log("account", accounts[0]);
      const res = await erc20Token
        .deploy({
          data: EtherToken.bytecode,
          arguments: [
            props.tokenDetails.data.crypto_token.name,
            props.tokenDetails.data.crypto_token.token_symbol,
            max_token_supply,
            props.tokenDetails.data.crypto_token.token_decimals,
          ],
        })
        .estimateGas({ from: accounts[0] });

      setGasEstimation(web3.utils.fromWei(res.toString(), "ether"));
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const deployContract = async (TokenDetails, values) => {
    setDeployContractButtonContent("Deploying contract...");
    const web3 = window.web3;
    const erc20Token = new web3.eth.Contract(TokenDetails.abi);
    const max_token_supply = values.max_token_supply.toString();
    try {
      const res = await erc20Token
        .deploy({
          data: TokenDetails.bytecode,
          arguments: [
            values.name,
            values.token_symbol,
            max_token_supply,
            values.token_decimals,
            account,
          ],
        })
        .send(
          {
            from: account,
            // gas: 5000000,
            // gasPrice: web3.utils.toWei(gasEstimation),
          },
          function (error, transactionHash) {
            // API call....
            console.log("Txt", transactionHash);
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          props.dispatch(
            saveTokenContractAddrStart({
              crypto_token_id:
                props.tokenDetails.data.crypto_token.crypto_token_id,
              contract_address: newContractInstance.options.address,
            })
          );
          const notificationMessage = getSuccessNotificationMessage(
            "Contract deployed. Please check the metamask.."
          );
          props.dispatch(createNotification(notificationMessage));
          console.log(newContractInstance.options.address); // instance with the new contract address
          setDeployContractButtonContent("");
          // Save the token contract address.
        });
    } catch (error) {
      console.log(error)
      const notificationMessage = getErrorNotificationMessage(
        "Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      setDeployContractButtonContent("");
    }
  };

  const checkMetaMaskNetwork = async (value) => {
    // try {
    //   let web3 = window.web3;
    //   const networkId = await web3.eth.net.getId();
    //   console.log("Network Id", networkId);
    //   if (value == "ether" && networkId == etherNetID) {
    //     // network is connected on Ropsten or Ether network.
    //     setMetamaskNetStatus("ether");
    //     console.log("Ether");
    //   } else if (value == "bnb" && networkId == binanceNetID) {
    //     setMetamaskNetStatus("bnb");
    //     console.log("bnb");
    //   } else if (value == "polygon" && networkId == polygonNetID) {
    //     setMetamaskNetStatus("polygon");
    //     console.log("polygon");
    //   } else {
    //     window.alert(
    //       "Please change the network into " +
    //         value +
    //         ". Once you changed the network in metamask refresh the page."
    //     );
    //     setMetamaskNetStatus("");
    //   }
    // } catch (error) {
    //   const notificationMessage = getErrorNotificationMessage(
    //     "Please try again..."
    //   );
    //   props.dispatch(createNotification(notificationMessage));
    //   console.log("Payment is not done..", error);
    //   return;
    // }
    // setAuth({
    //   ...auth,
    //   chainId: Number(value),
    // });
    setCurrentChainId(Number(value));
  };

  const startDeployContractProcess = async () => {
    let tempNetwork = "";
    let token = null;

    switch (props.tokenDetails.data.crypto_token.network_type) {
      case "ether":
        // checkMetaMaskNetwork("ether");
        token = EtherToken;
        tempNetwork = "ether";
        break;
      case "bnb":
        // checkMetaMaskNetwork("bnb");
        token = BinanceToken;
        tempNetwork = "bnb";
        break;
      case "polygon":
        // checkMetaMaskNetwork("polygon");
        token = PolygonToken;
        tempNetwork = "polygon";
        break;
      default:
        token = null;
        break;
    }
    if (token !== null && tempNetwork === metamaskNetStatus)
      deployContract(token, props.tokenDetails.data.crypto_token);
    else {
      let message = "The Selected network is wrong, please select " + props.tokenDetails.data.crypto_token.network_type;
      const notificationMessage = getErrorNotificationMessage( message );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  useEffect(() => {
    if (chainId && account) {
      findNet().then((val) => setCrytoSymbol(val));
    }
  }, [chainId]);

  const handleConnectWalletClose = () => setConnectWalletModalStatus(false);
  const handleConnectWalletOpen = () => setConnectWalletModalStatus(true);

  useEffect(() => {
    if (account) {
      handleConnectWalletClose();
    }
  }, [account]);

  const getPrice = async () => {
    let networkName;

    switch (auth.chainId) {
      case etherNetID:
        networkName = "ether";
        break;
      case binanceNetID:
        networkName = "binancecoin";
        break;
      case polygonNetID:
        networkName = "matic-network";
        break;
      case velasNetID:
        networkName = "velas";
        break;
      default:
        networkName = null;
        break;
    }

    const priceData = await axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${networkName}&vs_currencies=usd`,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data[networkName].usd;
        } else {
          return null;
        }
      });
    setPriceUsD(priceData);
  };

  useEffect(() => {
    getPrice();
  }, [auth.chainId]);

  const switchNetwork = async () => {
    setAuth({
      ...auth,
      chainId: currentChainId,
    });
    handleConnectWalletOpen()
  };

  return (
    <>
      <div
        className="content-wrapper min-heigth-100vh transaction-table"
        id="token-confirmation"
      >
        <section className="content-header">
          <h1>Deploy Token Contract</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Tokens</a>
            </li>
            <li className="breadcrumb-item active">Deploy your contract</li>
          </ol>
        </section>
        {
          props.tokenDetails.loading ? (
            "Loading... ?"
          ) : (
            // props.tokenDetails.data.crypto_token.deploy_status == 0 ? (
            //   props.tokenDetails.data.crypto_token.payment_status == 1 ? (
            <>
              <section className="center-container">
                <div className="container-fluid text-center">
                  <div className="custom-box p-3 p-lg-4 custom-shadow m-0 col-lg-9">
                    <div className="row no-gutters">
                      <div className="col-md-6">
                      <div className="deploy-image-wrapper">
                          <img src={window.location.origin + "/assets/images/deploy_contract.png"} alt="" />
                        </div>
                      </div>
                      <div className="col-md-6 align-items-center d-flex justify-content-center mt-3 mt-md-0">
                        <div className="admin-amount-wrapper">
                          <div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Token Name <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {props.tokenDetails.data.crypto_token.name}
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Total Supply <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {
                                  props.tokenDetails.data.crypto_token
                                    .max_token_supply
                                }
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Token Symbol <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {
                                  props.tokenDetails.data.crypto_token
                                    .token_symbol
                                }
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Decimal Point <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {
                                  props.tokenDetails.data.crypto_token
                                    .token_decimals
                                }
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Network Type <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {
                                  props.tokenDetails.data.crypto_token
                                    .network_type
                                }
                              </h5>
                            </div>
                            {auth.authStatus && Number(auth.chainId) == currentChainId ? (
                              <>
                                <div className="info-wrap">
                                  <h5 className="text-bold m-0 whitecolor">
                                    Wallet Address <span className="mx-3">:</span>
                                  </h5>
                                  <h5 className="m-0 account">
                                    {auth.accounts.substr(0, 5)}...
                                    {auth.accounts.substr(auth.accounts.length - 4)}
                                  </h5>
                                </div>
                                <div className="info-wrap">
                                  <h5 className="text-bold m-0 whitecolor">
                                    Balance <span className="mx-3">:</span>
                                  </h5>
                                  <h5 className="m-0">
                                    {Number(auth.ethBalance).toLocaleString(
                                      undefined,
                                      { maximumFractionDigits: 3 }
                                    )}{" "}
                                    {
                                      CHAIN_INFO[auth.chainId]
                                        .nativeCurrency.symbol
                                    }
                                  </h5>
                                </div>
                              </>
                            ) : null }
                          </div>

                          {/* {connectMetaMask ? (
                            <RadioGroup
                              aria-label="coin"
                              name="coin"
                              row
                              value={metamaskNetStatus}
                              onChange={(event) => {
                                if (connectMetaMask)
                                  checkMetaMaskNetwork(event.target.value);
                                else {
                                  const notificationMessage =
                                    getErrorNotificationMessage(
                                      "Connect metamask first..."
                                    );
                                  props.dispatch(
                                    createNotification(notificationMessage)
                                  );
                                }
                              }}
                            >
                              <FormControlLabel
                                value="ether"
                                control={<Radio color="primary" />}
                                label="Ethereum"
                              />
                              <FormControlLabel
                                value="polygon"
                                control={<Radio color="primary" />}
                                label="Polygon"
                              />
                              <FormControlLabel
                                value="bnb"
                                control={<Radio color="primary" />}
                                label="BNB"
                              />
                            </RadioGroup>
                          ) : (
                            ""
                          )} */}

                          <RadioGroup
                            aria-label="coin"
                            name="coin"
                            row
                            value={currentChainId}
                            onChange={(event) =>
                              checkMetaMaskNetwork(event.target.value)
                            }
                          >
                            {Object.entries(SupportedChainId).map(
                              (chain, index) => (
                                <>
                                  <FormControlLabel
                                    value={
                                      Object.entries(SupportedChainId)[index][1]
                                    }
                                    control={<Radio color="primary" />}
                                    label={
                                      CHAIN_INFO[
                                        Object.entries(SupportedChainId)[
                                          index
                                        ][1]
                                      ].nativeCurrency.symbol
                                    }
                                    key={index}
                                  />
                                </>
                              )
                            )}
                          </RadioGroup>
                          <div className="mt-2 text-center">
                            {(active === false && chainId == undefined) || Number(auth.chainId) != currentChainId ? (
                              <div className="buttons-wrapper">
                                <button
                                  type="submit"
                                  className="btn btn-primary withTheme web3-button-connect"
                                  //onClick={loadWeb3}
                                  onClick={() => switchNetwork()}
                                >
                                  Connect Wallet
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="buttons-wrapper">
                                  <button
                                    type="submit"
                                    className="btn btn-primary withTheme"
                                    onClick={startDeployContractProcess}
                                    disabled={
                                      deployContractButtonContent !== ""
                                        ? true
                                        : false
                                    }
                                  >
                                    {deployContractButtonContent !== ""
                                      ? deployContractButtonContent
                                      : "Deploy Contract"}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger withTheme web3-button-connect"
                                    onClick={hanldeLogout}
                                  >
                                    Logout
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          {/* <div className="mt-2 text-center">
                            {connectMetaMask === false ? (
                              <button
                                type="submit"
                                className="btn btn-primary withTheme"
                                onClick={loadWeb3}
                              >
                                Connect MetaMask
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary withTheme"
                                onClick={startDeployContractProcess}
                                disabled={
                                  deployContractButtonContent !== ""
                                    ? true
                                    : false
                                }
                              >
                                {deployContractButtonContent !== ""
                                  ? deployContractButtonContent
                                  : "Deploy Contract"}
                              </button>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
          //   ) : (
          //     <Redirect to="/tokens/tokens-list" />
          //   )
          // ) : (
          //   <Redirect to="/tokens/tokens-list" />
          // )
        }
      </div>
      {connectWalletModalStatus && (
        <ConnectWalletModal
          status={connectWalletModalStatus}
          handleConnectWalletClose={handleConnectWalletClose}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenDetails: state.token.tokenDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeployContract);
