export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const USERNAME_AVAILABILITY_START = "USERNAME_AVAILABILITY_START";
export const USERNAME_AVAILABILITY_SUCCESS = "USERNAME_AVAILABILITY_SUCCESS";
export const USERNAME_AVAILABILITY_FAILURE = "USERNAME_AVAILABILITY_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const SET_PASSWORD_START = "SET_PASSWORD_START";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const MODEL_ENROLL_START = "MODEL_ENROLL_START";
export const MODEL_ENROLL_SUCCESS = "MODEL_ENROLL_SUCCESS";
export const MODEL_ENROLL_FAILURE = "MODEL_ENROLL_FAILURE";

// Cards constants

export const FETCH_CARDS_START = "FETCH_CARDS_START";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_FAILURE = "FETCH_CARDS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Wallet constants
export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

export const WALLET_HISTORY_START = "WALLET_HISTORY_START";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAILURE = "WALLET_HISTORY_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Registeration verify.
export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages
export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off
export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Notification constant
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

// Wishlists constant
export const GET_WISHLIST_START = "GET_WISHLIST_START";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURE";

export const ADD_WISHLIST_START = "ADD_WISHLIST_START";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_FAILURE = "ADD_WISHLIST_FAILURE";

export const CLEAR_WISHLIST_START = "CLEAR_WISHLIST_START";
export const CLEAR_WISHLIST_SUCCESS = "CLEAR_WISHLIST_SUCCESS";
export const CLEAR_WISHLIST_FAILURE = "CLEAR_WISHLIST_FAILURE";

// verification document

export const FETCH_VERI_DOCUMENT_START = "FETCH_VERI_DOCUMENT_START";
export const FETCH_VERI_DOCUMENT_SUCCESS = "FETCH_VERI_DOCUMENT_SUCCESS";
export const FETCH_VERI_DOCUMENT_FAILURE = "FETCH_VERI_DOCUMENT_FAILURE";

export const SAVE_VERI_DOC_START = "SAVE_VERI_DOC_START";
export const SAVE_VERI_DOC_SUCCESS = "SAVE_VERI_DOC_SUCCESS";
export const SAVE_VERI_DOC_FAILURE = "SAVE_VERI_DOC_FAILURE";

export const DEL_VERI_DOC_START = "DEL_VERI_DOC_START";
export const DEL_VERI_DOC_SUCCESS = "DEL_VERI_DOC_SUCCESS";
export const DEL_VERI_DOC_FAILURE = "DEL_VERI_DOC_FAILURE";

export const VERI_STATUS_CHECK_START = "VERI_STATUS_CHECK_START";
export const VERI_STATUS_CHECK_SUCCESS = "VERI_STATUS_CHECK_SUCCESS";
export const VERI_STATUS_CHECK_FAILURE = "VERI_STATUS_CHECK_FAILURE";

export const DELETE_ALL_VERI_DOC_START = "DELETE_ALL_VERI_DOC_START";
export const DELETE_ALL_VERI_DOC_SUCCESS = "DELETE_ALL_VERI_DOC_SUCCESS";
export const DELETE_ALL_VERI_DOC_FAILURE = "DELETE_ALL_VERI_DOC_FAILURE";

export const FETCH_SINGLE_VERI_DOC_START = "FETCH_SINGLE_VERI_DOC_START";
export const FETCH_SINGLE_VERI_DOC_SUCCESS = "FETCH_SINGLE_VERI_DOC_SUCCESS";
export const FETCH_SINGLE_VERI_DOC_FAILURE = "FETCH_SINGLE_VERI_DOC_FAILURE";

// Subscription constants

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_STRIPE_START =
  "SUBSCRIPTION_PAYMENT_STRIPE_START";
export const SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS =
  "SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS";
export const SUBSCRIPTION_PAYMENT_STRIPE_FAILURE =
  "SUBSCRIPTION_PAYMENT_STRIPE_FAILURE";

export const SUBSCRIPTION_PAYMENT_WALLET_START =
  "SUBSCRIPTION_PAYMENT_WALLET_START";
export const SUBSCRIPTION_PAYMENT_WALLET_SUCCESS =
  "SUBSCRIPTION_PAYMENT_WALLET_SUCCESS";
export const SUBSCRIPTION_PAYMENT_WALLET_FAILURE =
  "SUBSCRIPTION_PAYMENT_WALLET_FAILURE";

export const SAVE_SUB_PAYMENT_CRYPTO_START = "SAVE_SUB_PAYMENT_CRYPTO_START";
export const SAVE_SUB_PAYMENT_CRYPTO_SUCCESS =
  "SAVE_SUB_PAYMENT_CRYPTO_SUCCESS";
export const SAVE_SUB_PAYMENT_CRYPTO_FAILURE =
  "SAVE_SUB_PAYMENT_CRYPTO_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Project constants

export const ADD_PROJECT_START = "ADD_PROJECT_START";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILURE = "ADD_PROJECT_FAILURE";

export const FETCH_OWN_PROJECT_START = "FETCH_OWN_PROJECT_START";
export const FETCH_OWN_PROJECT_SUCCESS = "FETCH_OWN_PROJECT_SUCCESS";
export const FETCH_OWN_PROJECT_FAILURE = "FETCH_OWN_PROJECT_FAILURE";

export const FETCH_SINGLE_OWN_PROJECT_START = "FETCH_SINGLE_OWN_PROJECT_START";
export const FETCH_SINGLE_OWN_PROJECT_SUCCESS =
  "FETCH_SINGLE_OWN_PROJECT_SUCCESS";
export const FETCH_SINGLE_OWN_PROJECT_FAILURE =
  "FETCH_SINGLE_OWN_PROJECT_FAILURE";

export const DELETE_OWN_PROJECT_START = "DELETE_OWN_PROJECT_START";
export const DELETE_OWN_PROJECT_SUCCESS = "DELETE_OWN_PROJECT_SUCCESS";
export const DELETE_OWN_PROJECT_FAILURE = "DELETE_OWN_PROJECT_FAILURE";

// Cryto Wallet

export const SAVE_WALLET_PAYMENT_DETAILS_START =
  "SAVE_WALLET_PAYMENT_DETAILS_START";
export const SAVE_WALLET_PAYMENT_DETAILS_SUCCESS =
  "SAVE_WALLET_PAYMENT_DETAILS_SUCCESS";
export const SAVE_WALLET_PAYMENT_DETAILS_FAILURE =
  "SAVE_WALLET_PAYMENT_DETAILS_FAILURE";

export const FETCH_INVESTED_PROJECT_START = "FETCH_INVESTED_PROJECT_START";
export const FETCH_INVESTED_PROJECT_SUCCESS = "FETCH_INVESTED_PROJECT_SUCCESS";
export const FETCH_INVESTED_PROJECT_FAILURE = "FETCH_INVESTED_PROJECT_FAILURE";

export const FETCH_PROJECT_ALL_START = "FETCH_PROJECT_ALL_START";
export const FETCH_PROJECT_ALL_SUCCESS = "FETCH_PROJECT_ALL_SUCCESS";
export const FETCH_PROJECT_ALL_FAILURE = "FETCH_PROJECT_ALL_FAILURE";

export const FETCH_SINGLE_PROJECT_START = "FETCH_SINGLE_PROJECT_START";
export const FETCH_SINGLE_PROJECT_SUCCESS = "FETCH_SINGLE_PROJECT_SUCCESS";
export const FETCH_SINGLE_PROJECT_FAILURE = "FETCH_SINGLE_PROJECT_FAILURE";

export const SAVE_INVESTMENT_TRANS_START = "SAVE_INVESTMENT_TRANS_START";
export const SAVE_INVESTMENT_TRANS_SUCCESS = "SAVE_INVESTMENT_TRANS_SUCCESS";
export const SAVE_INVESTMENT_TRANS_FAILURE = "SAVE_INVESTMENT_TRANS_FAILURE";

export const SEND_PROJECT_TOKEN_ADMIN_START = "SEND_PROJECT_TOKEN_ADMIN_START";
export const SEND_PROJECT_TOKEN_ADMIN_SUCCESS =
  "SEND_PROJECT_TOKEN_ADMIN_SUCCESS";
export const SEND_PROJECT_TOKEN_ADMIN_FAILURE =
  "SEND_PROJECT_TOKEN_ADMIN_FAILURE";

export const PROJECT_INVESTMENT_CLAIM_START = "PROJECT_INVESTMENT_CLAIM_START";
export const PROJECT_INVESTMENT_CLAIM_SUCCESS =
  "PROJECT_INVESTMENT_CLAIM_SUCCESS";
export const PROJECT_INVESTMENT_CLAIM_FAILURE =
  "PROJECT_INVESTMENT_CLAIM_FAILURE";

export const FETCH_FAQ_START = "FETCH_FAQ_START";
export const FETCH_FAQ_SUCCESS = "FETCH_FAQ_SUCCESS";
export const FETCH_FAQ_FAILURE = "FETCH_FAQ_FAILURE";

export const SEND_PROJECT_TOKEN_ADMIN_RESET = "SEND_PROJECT_TOKEN_ADMIN_RESET";

// Create token

export const SAVE_TOKEN_START = "SAVE_TOKEN_START";
export const SAVE_TOKEN_SUCCESS = "SAVE_TOKEN_SUCCESS";
export const SAVE_TOKEN_FAILURE = "SAVE_TOKEN_FAILURE";

export const FETCH_SINGLE_TOKEN_START = "FETCH_SINGLE_TOKEN_START";
export const FETCH_SINGLE_TOKEN_SUCCESS = "FETCH_SINGLE_TOKEN_SUCCESS";
export const FETCH_SINGLE_TOKEN_FAILURE = "FETCH_SINGLE_TOKEN_FAILURE";

export const SAVE_TOKEN_PAYMENT_AUTO_START = "SAVE_TOKEN_PAYMENT_AUTO_START";
export const SAVE_TOKEN_PAYMENT_AUTO_SUCCESS =
  "SAVE_TOKEN_PAYMENT_AUTO_SUCCESS";
export const SAVE_TOKEN_PAYMENT_AUTO_FAILURE =
  "SAVE_TOKEN_PAYMENT_AUTO_FAILURE";

export const SAVE_TOKEN_PAYMENT_MANUAL_START =
  "SAVE_TOKEN_PAYMENT_MANUAL_START";
export const SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS =
  "SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS";
export const SAVE_TOKEN_PAYMENT_MANUAL_FAILURE =
  "SAVE_TOKEN_PAYMENT_MANUAL_FAILURE";

export const SAVE_TOKEN_CONTRACT_ADDR_START = "SAVE_TOKEN_CONTRACT_ADDR_START";
export const SAVE_TOKEN_CONTRACT_ADDR_SUCCESS =
  "SAVE_TOKEN_CONTRACT_ADDR_SUCCESS";
export const SAVE_TOKEN_CONTRACT_ADDR_FAILURE =
  "SAVE_TOKEN_CONTRACT_ADDR_FAILURE";

export const FETCH_TOKEN_LIST_START = "FETCH_TOKEN_LIST_START";
export const FETCH_TOKEN_LIST_SUCCESS = "FETCH_TOKEN_LIST_SUCCESS";
export const FETCH_TOKEN_LIST_FAILURE = "FETCH_TOKEN_LIST_FAILURE";

export const TEMP_SAVE_TOKEN_START = "TEMP_SAVE_TOKEN_START";
export const TEMP_SAVE_TOKEN_SUCCESS = "TEMP_SAVE_TOKEN_SUCCESS";
export const TEMP_SAVE_TOKEN_FAILURE = "TEMP_SAVE_TOKEN_FAILURE";

export const FETCH_PAYMENT_DETAILS_START = "FETCH_PAYMENT_DETAILS_START";
export const FETCH_PAYMENT_DETAILS_SUCCESS = "FETCH_PAYMENT_DETAILS_SUCCESS";
export const FETCH_PAYMENT_DETAILS_FAILURE = "FETCH_PAYMENT_DETAILS_FAILURE";

export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const SAVE_SUPPORT_CONTACT_START = "SAVE_SUPPORT_CONTACT_START";
export const SAVE_SUPPORT_CONTACT_SUCCESS = "SAVE_SUPPORT_CONTACT_SUCCESS";
export const SAVE_SUPPORT_CONTACT_FAILURE = "SAVE_SUPPORT_CONTACT_FAILURE";
