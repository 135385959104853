import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchDashboardSuccess,
  fetchDashboardFailure
} from "../actions/DashboardAction"

import api from "../../Environment";
import {
 FETCH_DASHBOARD_START
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";

function* fetchDashboardAPI() {
  try {
    const response = yield api.postMethod("dashboard");
    if (response.data.success) {
      yield put(fetchDashboardSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage)); 
    }
  } catch (error) {
    yield put(fetchDashboardFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_DASHBOARD_START, fetchDashboardAPI),
  ]);
}