import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FbChatAfterLogin = (props) => {
 
  return (
    <>
      <div className="fb-chat-after-login">
        <a href="https://www.messenger.com/t/104425381888590" className="fb-msg-btn-chat" target="_blank" >
            Contact us on Facebook
        </a>
      </div>
    </>
  );
};

export default FbChatAfterLogin;
