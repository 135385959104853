import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SvgIcon from "../../SvgIcons/SvgIcon";

const FooterIndex = (props) => {
	return (
		<>
			<footer class="main-footer">
				<div class="pull-right d-none d-sm-inline-block">
					<ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
						{/* {configuration
							.get("configData.footer_pages1")
							.map((link, index) => (
								<li key={index} className="nav-item">
									<Link
										to={`/pages/${link.static_page_unique_id}`}
										className="text-capitalize nav-link"
									>
										{link.title}
									</Link>
								</li>
							))} */}
					</ul>
				</div>
				{configuration.get("configData.copyright_content") && (
					<p className="whitecolor text-capitalize mb-0 text-muted copyWrite w-100">
						{configuration.get("configData.copyright_content")}
					</p>
				)}
			</footer>
		</>
	);
};

export default FooterIndex;
