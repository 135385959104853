import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  All_supported_ChainIds,
  SUPPORTED_RPC_URLS,
  CHAIN_INFO,
  SELECTED_CHAIN_INFO,
} from "./chains";
import Web3 from "web3";

export const MetaMask = new InjectedConnector({
  supportedChainIds: All_supported_ChainIds,
});

export const walletConnectProvider = new WalletConnectProvider({
  rpc: SUPPORTED_RPC_URLS,
  chainId: 1,
  qrcode: true,
});

// export const walletconnect = (chainId) => {
//   const walletconnect = new WalletConnectConnector({
//     rpc: SUPPORTED_RPC_URLS[chainId],
//     supportedChainIds: [chainId],
//     qrcode: true,
//   });

//   return walletconnect ;
// };

export const walletconnect = new WalletConnectConnector({
  rpc: SUPPORTED_RPC_URLS,
  supportedChainIds: All_supported_ChainIds,
  qrcode: false,
  
});

// export const customWeb3Provider = new Web3(
//   new Web3.providers.HttpProvider("https://bsc-dataseed1.ninicoin.io")
// );
