import React from 'react'
import { Formik , Form , Field , ErrorMessage } from 'formik'
import * as Yup from 'yup';
import {Link} from 'react-router-dom'
import { changePasswordStart } from "../store/actions/UserAction";
import { connect } from "react-redux";

const ChangePassword = (props) => {

  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Old Password is required *"),
      password: Yup.string()
    .min(6, "Password must be 6 characters at minimum")
    .required("New Password is required *"),
    password_confirmation: Yup.string().required("ConfirmPassword is required")
    .test('passwords-match', 'Passwords must match', function(value){
      return this.parent.password === value
    })
  });

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>Change Password</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Profile</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Change Password</a>
            </li>
          </ol>
        </section>
        <section className="full-height-section">
          <div className="container place-center">
            <div class="custom-box col-lg-6">
              <h2 className="mb-3 whitecolor text-bold text-center">Change password</h2>
              <div class="row">
                <div class="col">
                  <Formik
                    initialValues={{
                      old_password: '',
                      password: '',
                      password_confirmation: '',
                    }}
                    validationSchema={changePasswordSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                  {({ errors, touched , setFieldValue}) => (
                    <Form noValidate>
                      <div className="row">
                        <div class="form-group col-md-12">
                          <h5>Old password <span class="text-danger">*</span></h5>
                          <div class="controls">
                            <Field 
                              type="password" 
                              name="old_password"
                              placeholder="old password"
                              className={`no-padding form-control ${
                                touched.old_password && errors.old_password ? "is-invalid" : ""
                              }`}
                            /> 
                            <ErrorMessage
                              component="div"
                              name="old_password"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-12">
                          <h5>New password <span class="text-danger">*</span></h5>
                          <div class="controls">
                            <Field 
                              type="password" 
                              name="password"
                              placeholder="new password"
                              className={`no-padding form-control ${
                                touched.password && errors.password ? "is-invalid" : ""
                              }`}
                            /> 
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-12">
                          <h5>confirm password <span class="text-danger">*</span></h5>
                          <div class="controls">
                            <Field 
                              type="password" 
                              name="password_confirmation"
                              placeholder="confirm password"
                              className={`no-padding form-control ${
                                touched.password_confirmation && errors.password_confirmation ? "is-invalid" : ""
                              }`}
                            /> 
                            <ErrorMessage
                              component="div"
                              name="password_confirmation"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                        </div>
                        
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link type="button" className="btn btn-primary withTheme mt-3 mt-md-0" to="/profile">Cancel</Link>
                        <button type="submit" className="btn btn-primary withTheme ml-sm-3 mt-3 mt-md-0" disabled={props.changePassword.buttonDisable}>
                          {props.changePassword.loadingButtonContent !== null
                          ? props.changePassword.loadingButtonContent
                          : "Save"}
                        </button>
                      </div>
                    </Form>
                  )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}


export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword)
