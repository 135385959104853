import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import ErrorSaga from "./ErrorSaga";
import NotificationSaga from "./NotificationSaga";
import PageSaga from "./PageSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import ProjectSaga from "./ProjectSaga";
import CryptoWalletSaga from "./CryptoWalletSaga";
import FaqSaga from './FaqSaga';
import TokenSaga from './TokenSaga';
import DashboardSaga from './DashboardSaga'

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(VerificationDocumentSaga)]);
  yield all([fork(SubscriptionSaga)]);
  yield all([fork(CryptoWalletSaga)]);
  yield all([fork(NotificationSaga)]);
  yield all([fork(ProjectSaga)]);
  yield all([fork(FaqSaga)]);
  yield all([fork(TokenSaga)]);
  yield all([fork(DashboardSaga)]);
}
