import React from "react";

const IcoDetails = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>ICO Details</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Initial Coin Offering</a>
            </li>
            <li className="breadcrumb-item active">ICO Details</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border">
                  <a href="#">
                    <i className="cc BAT no-margin" title="BAT"></i>
                  </a>
                  <h3 className="box-title">
                    Basic Attention Token
                    <small className="subtitle mt-0">Finance</small>
                  </h3>
                </div>
                <div className="box-body">
                  <p>
                    Basic Attention Token radically improves the efficiency of
                    digital advertising by creating a new token that can be
                    exchanged between publishers, advertisers, and users. It all
                    happens on the Ethereum blockchain. The token can be used to
                    obtain a variety of advertising and attention-based services
                    on the Brave platform. The utility of the token is based on
                    user attention, which simply means a person’s focused mental
                    engagement.
                  </p>

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="video">
                        <embed
                          className="w-p100 b-0 h-450"
                          allowfullscreen
                          src="https://www.youtube.com/embed/9xwazD5SyVg"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="box text-center bg-dark box-shadowed">
                        <div className="box-body">
                          <h2 className="mt-20">Token Sale ends in</h2>
                          <h4 className="text-bold">12 DAYS</h4>
                          <h2 className="text-bold text-success mt-20">
                            $4.200.000
                          </h2>
                          <h4 className="text-bold">of</h4>
                          <h4 className="text-bold mb-40">$12.000.000</h4>
                          <a href="#" className="btn btn-dark btn-lg mb-20">
                            WEBSITE
                          </a>
                          <br />
                          <a href="#" className="btn btn-dark btn-lg mb-30">
                            WHITEPAPER
                          </a>
                          <div className="mt-10 mb-20">
                            <button className="btn btn-social-icon btn-circle btn-facebook">
                              <i className="fa fa-facebook"></i>
                            </button>
                            <button className="btn btn-social-icon btn-circle btn-twitter">
                              <i className="fa fa-twitter"></i>
                            </button>
                            <button className="btn btn-social-icon btn-circle btn-linkedin">
                              <i className="fa fa-linkedin"></i>
                            </button>
                            <button className="btn btn-social-icon btn-circle btn-vimeo">
                              <i className="fa fa-vimeo"></i>
                            </button>
                            <button className="btn btn-social-icon btn-circle btn-instagram">
                              <i className="fa fa-instagram"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="box text-center box-inverse box-success">
                        <div className="box-header with-border">
                          <h3 className="box-title">Market Price</h3>
                        </div>
                        <div className="box-body">
                          <div className="div-market-price">
                            <h1 className="mb-25">
                              $1,231.91 <small>USD</small>
                            </h1>
                            <h1 className="mb-25">
                              $0,231.22 <small>EUR</small>
                            </h1>
                            <h1 className="mb-25">
                              $1,245.61 <small>GBR</small>
                            </h1>
                            <h3 className="text-white p-10">
                              Average USD/EUR/GBR market price across major
                              bitcoin exchanges.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="box-header with-border">
                    <h4 className="mb-0 font-weight-500">
                      <span className="text-green icon-calendar mr-10"></span>{" "}
                      TOKEN SALE 25 FEB - 38 NOV
                    </h4>
                  </div>

                  <div className="row mb-30">
                    <div className="col-12 col-lg-4">
                      <h5 className="p-15 mb-0">
                        <strong>Ticker:</strong> CGCOIN
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Token Type:</strong> ERC20
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Fundraising Goal:</strong> 2,234,000 USD
                      </h5>
                    </div>
                    <div className="col-12 col-lg-4">
                      <h5 className="p-15 mb-0">
                        <strong>Can’t participate:</strong> USA
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Accepts:</strong> EHT, BTC, USD
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Bonus for the First:</strong> Up to 30% bonus
                      </h5>
                    </div>
                    <div className="col-12 col-lg-4">
                      <h5 className="p-15 mb-0">
                        <strong>Total Tokens:</strong> 90,000,000
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Available for Token Sale:</strong>
                        <span className="text-green"> 67%</span>
                      </h5>
                    </div>
                  </div>

                  <div className="box-header with-border">
                    <h4 className="mb-0 font-weight-500">
                      <span className="text-green icon-info mr-10"></span> SHORT
                      REVIEW
                    </h4>
                  </div>

                  <div className="row mb-30">
                    <div className="col-12 col-lg-4">
                      <h5 className="p-15 mb-0">
                        <strong>Team form:</strong> Singapore
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Role of Token:</strong> Utility
                      </h5>
                    </div>
                    <div className="col-12 col-lg-8">
                      <h5 className="p-15 mb-0">
                        <strong>Unsold Tokens:</strong> Will be burn
                      </h5>
                      <h5 className="p-15 mb-0">
                        <strong>Registered Company:</strong> Crowd Genie
                        Financial Services PTE. LTD.
                      </h5>
                    </div>
                  </div>

                  <div className="box-header with-border">
                    <h4 className="mb-0 font-weight-500">
                      <span className="text-green icon-picture mr-10"></span>{" "}
                      SCREENSHOTS
                    </h4>
                  </div>

                  <div className="popup-gallery p-15">
                    <a
                      href="../../../images/gallery/thumb/4.jpg"
                      title="Caption. Can be aligned to any side and contain any HTML."
                    >
                      <img
                        src="../../../images/gallery/thumb/4.jpg"
                        className="w-p20"
                        alt=""
                      />
                    </a>
                    <a
                      href="../../../images/gallery/thumb/5.jpg"
                      title="This image fits only horizontally."
                    >
                      <img
                        src="../../../images/gallery/thumb/5.jpg"
                        className="w-p20"
                        alt=""
                      />
                    </a>
                    <a href="../../../images/gallery/thumb/6.jpg">
                      <img
                        src="../../../images/gallery/thumb/6.jpg"
                        className="w-p20"
                        alt=""
                      />
                    </a>
                    <a href="../../../images/gallery/thumb/6.jpg">
                      <img
                        src="../../../images/gallery/thumb/1.jpg"
                        className="w-p20"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IcoDetails;
