import React from 'react'
import { Helmet } from 'react-helmet'

const LogoutLayout = (props) => {
  return (
    <>
     <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/css/landingstyle.css"}
          id="changeTheme"
        ></link>
      </Helmet>
      {props.children}
    </>
  )
}

export default LogoutLayout
