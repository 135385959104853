import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import AdditionalInfo from "./AdditionalInfo";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return (
    <Tooltip arrow placement={props.placement} classes={classes} {...props} />
  );
}

const SingleTransaction = (props) => {

  const {transactionDetail} = props.location.state;

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Transactions Details</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Transactions</a>
            </li>
            <li className="breadcrumb-item active">Transactions Details</li>
          </ol>
        </section>

        <section className="content w-100">
          {/* <div className="row">
            <div className="col-lg-6 col-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">Summary</h3>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover no-margin">
                      <tbody>
                        <tr>
                          <td>Address</td>
                          <td>
                            <a href="#">
                              2dxg25454841132457d21564d1321d35d5zxd54d545dv4v34d54v5d4v5d4x54d
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Hash 160</td>
                          <td>
                              <a href="#">3df2dfds24478454d445d5sf454df545d</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Tools</td>
                          <td>Related Tags - Unspent Outputs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">Transactions</h3>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover no-margin">
                      <tbody>
                        <tr>
                          <td>No. Transactions</td>
                          <td>
                            <span className="text-success">54</span>
                          </td>
                          <td>
                            <i className="fa fa-bar-chart"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Received</td>
                          <td>
                            <span className="text-success">
                              95.123456789 BTC
                            </span>
                          </td>
                          <td>
                            <i className="fa fa-bar-chart"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Balance</td>
                          <td>
                            <span className="text-success">0 BTC</span>
                          </td>
                          <td>
                            <i className="fa fa-bar-chart"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-12">
              <div className="box">
                <div className="box-body">
                  <img
                    src={window.location.origin + "/assets/images/qrcode.png"}
                    alt="codeQR"
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="box">
            <div className="box-body">
              <h3 className="mt-0">Transactions Details</h3>
            </div>
          </div>
          <div className="single-transaction-table">
            <ul>
              <li>
                <h6 className="text-primary">
                  [ This is a POLY Testnet transaction only ]
                </h6>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className=" col-lg-4 col-md-6 col-sm-6">
                    <div className="title">
                      <BootstrapTooltip
                        title="Transaction Hash"
                        placement="right"
                      >
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Transaction Hash :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05">
                      <h6 className="text-no-overflow mb-0 mt-3 mt-lg-0">
                        {transactionDetail.hash}
                      </h6>
                      <BootstrapTooltip
                        title="Copy to clipboard"
                        placement="top"
                      >
                        <span className="text-bold">
                          <i className="far fa-copy font-14"></i>
                        </span>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Status" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Status :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    <div className="">
                      {transactionDetail.confirmations != "" ? (
                        <BootstrapTooltip title="success" placement={"top"}>
                          <div className="transaction0-status">
                            <i className="far fa-check-circle"></i>
                            <p>Success</p>
                          </div>
                        </BootstrapTooltip>
                      ): (
                        <BootstrapTooltip title="failed" placement={"top"}>
                        <div className="transaction0-status">
                          <i className="far fa-check-circle"></i>
                          <p>Failed</p>
                        </div>
                      </BootstrapTooltip>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Block" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Block :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05 mt-2 mt-md-0">
                      <Link
                        to="#"
                        className="mb-0 text-primary text-bold font-14"
                      >
                        {transactionDetail.blockNumber}
                      </Link>
                      <BootstrapTooltip title="45021 Block Confirmations" placement="top">
                        <div className="coin-name-wrapper">
                          <p className="mb-0">{transactionDetail.confirmations} Block Confirmations</p>
                        </div>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Timestamp" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Timestamp :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05 mt-2 mt-md-0">
                      <i className="far fa-clock"></i>
                      <h6 className="mb-0">
                        {moment(Number(transactionDetail.timeStamp)).format("DD-MM-YYYY h:mm:ss")}
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Timestamp" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">From :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05 mt-2 mt-md-0">
                      <Link
                        to="#"
                        className="mb-0 text-primary text-bold font-14 text-no-overflow"
                      >
                        {transactionDetail.from}
                      </Link>
                      <BootstrapTooltip
                        title="Copy to clipboard"
                        placement="top"
                      >
                        <span className="text-bold">
                          <i className="far fa-copy font-14"></i>
                        </span>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Timestamp" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Interacted With (To) :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05 mt-2 mt-md-0">
                      <h6 className="mb-0 text-no-overflow">
                        [Contract{" "}
                        <Link to="#" className="mb-0 text-primary text-bold ">
                        {transactionDetail.to}
                        </Link>{" "}
                        ] Created
                      </h6>
                      <BootstrapTooltip title="Success" placement="top">
                        <span className="text-bold">
                          <h6 className="mb-0">
                            <i className="far fa-check-circle text-success"></i>
                          </h6>
                        </span>
                      </BootstrapTooltip>
                      <BootstrapTooltip
                        title="Copy to clipboard"
                        placement="top"
                      >
                        <span className="text-bold">
                          <i className="far fa-copy font-14"></i>
                        </span>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Timestamp" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Tokens Transferred :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex gap-05 mt-2 mt-md-0">
                          <h6 className="mb-0 text-bold">From:</h6>
                          <BootstrapTooltip
                            title="0xf6ae57ae312e16df7e807da6b0c7132f337f8676"
                            placement="top"
                          >
                            <h6 className="mb-0 text-no-overflow text-primary">
                            {transactionDetail.from}
                            </h6>
                          </BootstrapTooltip>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex gap-05 mt-2 mt-md-0">
                          <h6 className="mb-0 text-bold">To:</h6>
                          <BootstrapTooltip
                            title="0xf6ae57ae312e16df7e807da6b0c7132f337f8676"
                            placement="top"
                          >
                            <h6 className="mb-0 text-no-overflow text-primary ">
                              {transactionDetail.to}
                            </h6>
                          </BootstrapTooltip>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex gap-05 mt-3">
                          <h6 className="mb-0 text-bold">For: </h6>
                          <h6 className="mb-0 text-no-overflow">{transactionDetail.value}</h6>
                          <h6 className="mb-0 text-bold text-primary text-no-overflow">
                            {transactionDetail.tokenSymbol}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip title="Value" placement="right">
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Value :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    <div className="d-flex align-items-center gap-05">
                      <BootstrapTooltip title="Matic" placement="top">
                        <div className="coin-name-wrapper">
                          <p className="mb-0">{transactionDetail.tokenSymbol}</p>
                        </div>
                      </BootstrapTooltip>
                      <h6 className="mb-0">($0.00)</h6>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center">
                    <div className="title">
                      <BootstrapTooltip
                        title="Transaction Fee"
                        placement="right"
                      >
                        <span className="text-bold">
                          <i class="far fa-question-circle ml-2 "></i>
                        </span>
                      </BootstrapTooltip>
                      <h6 className="mb-0">Transaction Fee: :</h6>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-05 mt-2 mt-md-0">
                      <BootstrapTooltip
                        title="0.09821898 MATIC"
                        placement="top"
                      >
                        <h6 className="mb-0 text-no-overflow">{transactionDetail.value} {transactionDetail.tokenSymbol}</h6>
                      </BootstrapTooltip>
                      <BootstrapTooltip title="$0.00" placement="top">
                        <div className="coin-name-wrapper">
                          <h6 className="mb-0">($0.00)</h6>
                        </div>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default SingleTransaction;
