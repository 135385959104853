import React from 'react'

const DisctributionCountdown = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Distribution Countdown
          </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
            <li className="breadcrumb-item"><a href="#">Initial Coin Offering</a></li>
            <li className="breadcrumb-item active">Distribution Countdown</li>
          </ol>
        </section>

        <section className="content">
        <div className="row">
        <div className="col-12">
          <h1 className="page-header text-center text-white">
            <span className="text-bold">Crypto</span> The <span className="text-bold">Revolution</span> In Virtual <span className="text-bold">Coin</span><br />
            <p className="font-size-18 mb-0">Buy now and get +30% extra bonus Minimum pre-sale amount 50 Crypto Coin. We accept BTC crypto-currency.</p>
          </h1>

          <div className="box box-dark">
          <div className="box-body">
            <h5 className="text-white text-center">Distribution Ends In:</h5>
            <div className="countdownv2_holder text-center mb-50 mt-20">
              <div className="clock"></div>
              </div>
            <div className="text-center">
              <a href="#" className="btn btn-warning">BUY TOKENS</a>				
            </div>
              <h6 className="text-white text-left mb-2">Midcap <span className="text-bold">in 489 days</span></h6>
              <div className="progress mb-2">
              <div className="progress-bar progress-bar-warning progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width:"60%"}}>
                <span className="text-left pl-2">58$</span>
              </div>
              </div>
              <h6 className="text-white text-right no-margin">Hardcap</h6>
              <ul className="flexbox flex-justified text-center my-10">
                <li className="br-1">
                <div className="font-size-20 text-primary">Total Tokens:  <span className="text-bold">123.0122</span></div>						
                </li>

                <li className="br-1">
                <div className="font-size-20 text-info">Tokens In Circulation:  <span className="text-bold">5603.3011</span></div>
                </li>

                <li>
                <div className="font-size-20 text-yellow">Token Price:  <span className="text-bold">$1.0023</span></div>						
                </li>
              </ul>
          </div>
          </div>

        </div>		  
        </div>
        </section>
      </div>
    </>
  )
}

export default DisctributionCountdown
