import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { fetchSinglePageStart } from "../store/actions/PageAction";
import { connect } from "react-redux";

const StaticPages = (props) => {
  const { id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSinglePageStart({ unique_id: id }));
  }, [id]);

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
      {props.pageData.loading ? (
        "Loading..."
      ) : (
        <div >
          <section className="content-header">
            <h1>{props.pageData.data.title}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">
                  <i className="fa fa-dashboard"></i> Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{props.pageData.data.title}</a>
              </li>
            </ol>
          </section>
          <section>
            <div className="container">
              <div className=" w-100 my-4">
                <>
                  {id == "contact" && (
                    <h3 className="text-center">{props.pageData.data.title}</h3>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.pageData.data.description,
                    }}
                  ></div>
                </>
              </div>
            </div>
          </section>
        </div>
      )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticPages);
