import React from "react";

const IcoListing = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>ICO Listing</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Initial Coin Offering</a>
            </li>
            <li className="breadcrumb-item active">ICO Listing</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc XRP mr-5" title="XRP"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Ripple</h3>
                    <span>Real Estate</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc BAT mr-5" title="BAT"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">BasicAtt</h3>
                    <span>Finance</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$2.000</span> / $3.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">40%</span>
                    </p>
                    <p className="no-margin">8d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc ADA mr-5" title="ADA"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Cardano</h3>
                    <span>Healthcare</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$13.900</span> / $25.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">62%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc LTC mr-5" title="LTC"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Litecoin</h3>
                    <span>Marketing</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$4.600</span> / $15.200
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">31%</span>
                    </p>
                    <p className="no-margin">25d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc XEM mr-5" title="XEM"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Nem</h3>
                    <span>Finance</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$9.600</span> / $20.000
                    </p>
                    <p className="no-margin">Neutral</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">48%</span>
                    </p>
                    <p className="no-margin">6d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc NEO mr-5" title="NEO"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">NEO</h3>
                    <span>Banking</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Neutral</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc EOS mr-5" title="EOS"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">EOS</h3>
                    <span>Exchange</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Medium</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc XMR mr-5" title="XMR"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Monero</h3>
                    <span>Real Estate</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Not Rated</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc QTUM mr-5" title="QTUM"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">QTUM</h3>
                    <span>Blockchain</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Not Rated</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc LSK mr-5" title="LSK"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Lisk</h3>
                    <span>Trading</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Not Rated</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc OMG mr-5" title="OMG"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">OmiGO</h3>
                    <span>Smart</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Not Rated</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc USDT mr-5" title="USDT"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Tether</h3>
                    <span>Advertising</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Medium</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc ZEC mr-5" title="ZEC"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Zcach</h3>
                    <span>Blockchain</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Medium</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc STRAT mr-5" title="STRAT"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Stratis</h3>
                    <span>Trading</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Neutral</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc ARDR mr-5" title="ARDR"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Ardor</h3>
                    <span>Finance</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc STEEM mr-5" title="STEEM"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Steem</h3>
                    <span>Finance</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc WAVES mr-5" title="WAVES"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Wavrs</h3>
                    <span>Banking</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc REP mr-5" title="REP"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Augur</h3>
                    <span>Exchange</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc KMD mr-5" title="KMD"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Komodo</h3>
                    <span>Real Estate</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="box box-body">
                <div className="media align-items-center p-0">
                  <div className="text-center">
                    <a href="#">
                      <i className="cc ARK mr-5" title="ARK"></i>
                    </a>
                  </div>
                  <div>
                    <h3 className="no-margin text-bold">Ark</h3>
                    <span>Blockchain</span>
                  </div>
                </div>
                <div className="flexbox align-items-center mt-25">
                  <div>
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">$25.000</span> / $30.000
                    </p>
                    <p className="no-margin">Sponsored</p>
                  </div>
                  <div className="text-right">
                    <p className="no-margin font-weight-600">
                      <span className="text-yellow">84%</span>
                    </p>
                    <p className="no-margin">9d left</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <nav>
            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="#">
                  <span className="ion-ios-arrow-thin-left"></span>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  <span className="ion-ios-arrow-thin-right"></span>
                </a>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </>
  );
};

export default IcoListing;
