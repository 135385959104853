import { hexStripZeros } from "@ethersproject/bytes";
import { Web3Provider } from "@ethersproject/providers";
import { SELECTED_CHAIN_INFO, SUPPORTED_RPC_URLS } from "./chains";
import { BigNumber } from "@ethersproject/bignumber";

export const changeNetwork = async (chainId) => {
  console.log("change network", chainId);
  const formattedChainId = hexStripZeros(BigNumber.from(Number(chainId)).toHexString());

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: formattedChainId }],
    });
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error.code === 4902) {
      const info = SELECTED_CHAIN_INFO(chainId);

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: formattedChainId,
            chainName: info.label,
            rpcUrls: [SUPPORTED_RPC_URLS[chainId]],
            nativeCurrency: info.nativeCurrency,
            blockExplorerUrls: [info.explorer],
          },
        ],
      });
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: formattedChainId }],
        });
      } catch (error) {
        console.debug("Added network but could not switch chains", error);
      }
    } else {
      throw error;
    }
  }
};
