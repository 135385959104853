import React from "react";

const TokenHelperModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="tokenHelper"
        tabindex="-1"
        role="dialog"
        aria-labelledby="tokenHelper"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content p-3">
            <div className="modal-header">
              <button
                type="button"
                className="close white-color p-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Have questions? Shoot us an email at :{" "}
                <a className="link" href="mailto:hello@tokencreator.space?Subject=subject here&amp;Body=bodytext" >
                  hello@tokencreator.space
                </a>
              </p>
              <p>
                <span className="mb-10">-- OR -- </span>
              </p>
              <p>
                Live Chat with us on FB Messenger{" "}
                <a
                  className="link"
                  href="https://www.messenger.com/t/104425381888590"
                  target="_blank"
                >
                  HERE
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenHelperModal;
