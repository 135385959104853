import React from 'react'
import { Formik , Form , Field , ErrorMessage } from 'formik'
import * as Yup from 'yup';
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { deleteAccountStart } from "../store/actions/UserAction";

const DeleteAccount = (props) => {

  const handleSubmit = (values) => {
    props.dispatch(deleteAccountStart({ password: values }));
  };


  const deleteAccountSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>Delete Account</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Profile</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Delete Account</a>
            </li>
          </ol>
        </section>
          <section className="full-height-section">
          <div className="container place-center">
            <div class="custom-box col-lg-12">
              <h2 className="mb-3 whitecolor text-bold text-center">Delete Account</h2>
              <h4 className="mb-3 whitecolor text-bold text-center">We will miss you</h4>
              <div class="row">
                <div class="col">
                  <Formik
                    initialValues={{ 
                      password : ''
                    }}
                    validationSchema={deleteAccountSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                  {({ errors, touched , setFieldValue}) => (
                    <Form noValidate>
                      <div className="row">
                        <div class="form-group col-md-12">
                          <h5>Password <span class="text-danger">*</span></h5>
                          <div class="controls">
                            <Field 
                              type="password" 
                              name="password" 
                              placeholder="password"
                              className={`no-padding form-control ${
                                touched.password && errors.password ? "is-invalid" : ""
                              }`}
                            /> 
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                        </div>                        
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link type="button" className="btn btn-primary withTheme mt-3 mt-md-0" to="/profile">Cancel</Link>
                        <button type="submit" className="btn btn-primary withTheme ml-sm-3 mt-3 mt-md-0" disabled={props.deleteAcc.loading && props.deleteAcc.buttonDisable}>
                        {props.deleteAcc.loading ? props.deleteAcc.loadingButtonContent !== null
                          ? props.deleteAcc.loadingButtonContent
                          : null :  "Delete Account"}
                        </button>
                      </div>
                    </Form>
                  )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccount)
