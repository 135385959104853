import React, { useEffect, useState } from "react";

const RandomOnlinePeople = () => {
  const [visitors, setVisitors] = useState(0);

  const [initialRender, setInitalRender] = useState(false);

  useEffect(() => {
    if (initialRender) {
      const interval = setInterval(() => {
        setVisitors(randomIntFromInterval(900, 2000));
      }, 10000);
      return () => clearInterval(interval);
    } else {
      setVisitors(randomIntFromInterval(900, 2000));
    }
    setInitalRender(true);
  }, [initialRender]);

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <>
      <div className="online-vistors">
        <p>{visitors} people online now</p>
      </div>
    </>
  );
};

export default RandomOnlinePeople;
