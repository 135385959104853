import React from "react";
import { connect } from "react-redux";
import {
  userRegisterStart,
  usernameAvailablityStart,
} from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";

const Register = (props) => {
  const handleSignup = (values) => {
    props.dispatch(userRegisterStart(values));
  };

  const usernameCheck = (value) => {
    // if (value.length >= 4)
    //   props.dispatch(
    //     usernameAvailablityStart({
    //       username: value,
    //     })
    //   );
  };

  const registerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    username: Yup.string()
      .min(4, "username must 4 characters at minimum")
      .required("Username is required *"),
    mobile: Yup.number().required("Mobile Number is Required"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  return (
    <>
      <div className="modal fade" id="register-popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <a
              href="#"
              className="modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <em className="ti ti-close"></em>
            </a>
            <div className="ath-container m-0">
              <div className="ath-body">
                <h5 className="ath-heading title">
                  Sign Up{" "}
                  <small className="tc-default">Create New {configuration.get("configData.site_name")} Account</small>
                </h5>
                <Formik
                  initialValues={{
                    name: "",
                    username: "",
                    mobile: "",
                    email: "",
                    password: "",
                  }}
                  validationSchema={registerSchema}
                  onSubmit={(values) => handleSignup(values)}
                >
                  {({ touched, errors, setFieldValue, isSubmitting }) => (
                    <Form noValidate>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            className={`input-bordered  ${touched.name && errors.name ? "is-invalid" : ""
                              }`}
                            type="text"
                            name="name"
                            placeholder="Your Name"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            type="text"
                            className={`input-bordered ${touched.username && errors.username
                              ? "is-invalid"
                              : ""
                              }`}
                            name="username"
                            placeholder="Username"
                            onChange={(event) => {
                              usernameCheck(event.target.value);
                              setFieldValue("username", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name="username"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </div>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            className={`input-bordered ${touched.email && errors.email ? "is-invalid" : ""
                              }`}
                            type="email"
                            name="email"
                            placeholder="Email Address"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            name="mobile"
                            type="number"
                            className={`input-bordered ${touched.mobile && errors.mobile
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Mobile Number"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="mobile"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            type="password"
                            className={`input-bordered ${touched.password && errors.password
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Password"
                            name="password"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                      {/* <div className="field-item">
                        <input
                          className="input-checkbox"
                          id="agree-term-2"
                          type="checkbox"
                        />
                        <label for="agree-term-2">
                          I agree to {configuration.get("configData.site_name")}{" "}
                          <a target="_blank" href="/home-pages/privacy">
                            Privacy Policy
                          </a>{" "}
                          &amp;{" "}
                          <a target="_blank" href="/home-pages/terms">
                            Terms
                          </a>
                          .
                        </label>
                      </div> */}
                      <button
                        className="btn btn-primary btn-block btn-md"
                        type="submit"
                        disabled={props.signup.buttonDisable}
                      >
                        {props.signup.loadingButtonContent !== null
                          ? props.signup.loadingButtonContent
                          : "SIGN UP"}
                      </button>
                    </Form>
                  )}
                </Formik>
                {/* <div className="sap-text">
                  <span>Or Sign Up With</span>
                </div>

                <ul className="btn-grp gutter-20px gutter-vr-20px">
                  <li className="col">
                    <a href="#" className="btn btn-md btn-facebook btn-block">
                      <em className="icon fab fa-facebook-f"></em>
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li className="col">
                    <a href="#" className="btn btn-md btn-google btn-block">
                      <em className="icon fab fa-google"></em>
                      <span>Google</span>
                    </a>
                  </li>
                </ul> */}
                <div className="ath-note text-center">
                  Already have an account?{" "}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-dismiss="modal"
                    data-target="#login-popup"
                  >
                    {" "}
                    <strong>Sign in here</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
  usernameAva: state.users.usernameAva,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Register);
