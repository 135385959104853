import React, { useRef } from "react";
import { Modal } from "react-bootstrap";

const ProcessInfoVideo = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        size="xl"
        onHide={props.handleProcessVideoModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="process-Video"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close white-color p-0"
            onClick={props.handleProcessVideoModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="video-wrapper">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/lsI01xK4Ado?autoplay=0"
              frameborder="0"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProcessInfoVideo;
