import React from 'react'

const TranscationCounter = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Transactions Counter
          </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
            <li className="breadcrumb-item"><a href="#">Transactions</a></li>
            <li className="breadcrumb-item active">Transactions Counter</li>
          </ol>
        </section>

        <section className="content">	  
        <div className="box box-inverse box-dark">
            <div className="box-body">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">325</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Transactions in last 24h</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">40</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Transactions per hour</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">150</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Largest Transactions</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">8</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Years of Experience</h5>
                        </div>
            </div>
          </div>
            </div>
          </div>      
        <div className="box">
            <div className="box-body">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">325</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Transactions in last 24h</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">40</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Transactions per hour</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">150</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Largest Transactions</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">8</h1>
                            <hr className="w-50 my-5 b-3 border-yellow" />
                            <h5 className="mt-10">Years of Experience</h5>
                        </div>
            </div>
          </div>
            </div>
          </div>      
        <div className="box box-inverse box-primary">
            <div className="box-body">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">325</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Transactions in last 24h</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">40</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Transactions per hour</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">150</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Largest Transactions</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">8</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Years of Experience</h5>
                        </div>
            </div>
          </div>
            </div>
          </div>      
        <div className="box box-inverse box-yellow">
            <div className="box-body">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">325</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Transactions in last 24h</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">40</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Transactions per hour</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">150</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Largest Transactions</h5>
                        </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="text-center">
                            <h1 className="text-bold count">8</h1>
                            <hr className="w-50 my-5 b-2 border-dark" />
                            <h5 className="mt-10">Years of Experience</h5>
                        </div>
            </div>
          </div>
            </div>
          </div>      
        </section>
      </div>
    </>
  )
}

export default TranscationCounter
