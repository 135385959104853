import {
  FETCH_FAQ_START,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAILURE,
  SAVE_SUPPORT_CONTACT_START,
  SAVE_SUPPORT_CONTACT_SUCCESS,
  SAVE_SUPPORT_CONTACT_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  faqData: {
    data: {},
    loading: true,
    error: false,
  },
  saveSupportContact: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
};

const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAQ_START:
      return {
        ...state,
        faqData: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_FAQ_SUCCESS:
      return {
        ...state,
        faqData: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_FAQ_FAILURE:
      return {
        ...state,
        faqData: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case SAVE_SUPPORT_CONTACT_START:
      return {
        ...state,
        saveSupportContact: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_SUPPORT_CONTACT_SUCCESS:
      return {
        ...state,
        saveSupportContact: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_SUPPORT_CONTACT_FAILURE:
      return {
        ...state,
        saveSupportContact: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };

    default:
      return state;
  }
};

export default FaqReducer;
