import {
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from './ActionConstant'

export function fetchDashboardStart(data) {
  return {
    type: FETCH_DASHBOARD_START,
    data,
  };
}

export function fetchDashboardSuccess(data) {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    data,
  };
}

export function fetchDashboardFailure(error) {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    error,
  };
}