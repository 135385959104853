import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { fetchSinglePageStart } from "../store/actions/PageAction";
import { connect } from "react-redux";

const LandingStaticPages = (props) => {

  const { id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSinglePageStart({ unique_id: id }));
  }, [id]);

  return (
    <>
      <section className="landing-other-pages">
        <div className="container-fluid">
        {props.pageData.loading ? (
        "Loading..."
      ) : (
        <div >
          <section>
            <div className="container">
              <div className=" w-100 my-4">
                <>
                  <h3 className="text-center text-capitalize">{props.pageData.data.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.pageData.data.description,
                    }}
                  ></div>
                </>
              </div>
            </div>
          </section>
        </div>
      )}
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LandingStaticPages);
