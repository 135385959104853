import React,{useEffect} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_rangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";

am4core.useTheme(am4themes_animated);

const LiveChart = () => {

  useEffect(() => {
    
		let chart = am4core.create("liveBitcoin", am4charts.XYChart);
		chart.padding(0, 15, 0, 15);

		let data = [];
		let visits = 10;
		let i = 0;

		for (i = 0; i <= 30; i++) {
				visits -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
				data.push({ date: new Date().setSeconds(i - 30), value: visits });
		}

		chart.data = data;

		console.log(data)

		// Load external data
		// chart.dataSource.url = "https://www.amcharts.com/wp-content/uploads/assets/stock/MSFT.csv";
		// chart.dataSource.parser = new am4core.CSVParser();
		// chart.dataSource.parser.options.useColumnNames = true;
		// chart.dataSource.parser.options.reverse = true;

		// the following line makes value axes to be arranged vertically.
		chart.leftAxesContainer.layout = "vertical";

		// uncomment this line if you want to change order of axes
		//chart.bottomAxesContainer.reverseOrder = true;

		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.ticks.template.length = 8;
		dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
		dateAxis.renderer.grid.template.disabled = true;
		dateAxis.renderer.ticks.template.disabled = false;
		dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
		dateAxis.renderer.minLabelPosition = 0.01;
		dateAxis.renderer.maxLabelPosition = 0.99;
		dateAxis.keepSelection = true;
		dateAxis.minHeight = 30;

		dateAxis.groupData = true;
		dateAxis.minZoomCount = 5;

		// these two lines makes the axis to be initially zoomed-in
		// dateAxis.start = 0.7;
		// dateAxis.keepSelection = true;

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;
		valueAxis.zIndex = 1;
		valueAxis.renderer.baseGrid.disabled = true;
		// height of axis
		valueAxis.height = am4core.percent(65);

		valueAxis.renderer.gridContainer.background.fill = am4core.color("#000000");
		valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
		valueAxis.renderer.inside = true;
		valueAxis.renderer.labels.template.verticalCenter = "bottom";
		valueAxis.renderer.labels.template.padding(2, 2, 2, 2);

		//valueAxis.renderer.maxLabelPosition = 0.95;
		valueAxis.renderer.fontSize = "0.8em"

		let series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.dateX = "date";
		series.dataFields.valueY = "value";
		series.tooltipText = "{valueY.value}";
		series.name = "MSFT: Value";
		series.defaultState.transitionDuration = 0;
		series.tooltip.getFillFromObject = false;
		series.tooltip.background.fill = am4core.color("#000000");
		series.tooltip.background.fillOpacity = 1;

		let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis2.tooltip.disabled = true;
		// height of axis
		valueAxis2.height = am4core.percent(35);
		valueAxis2.zIndex = 3
		// this makes gap between panels
		valueAxis2.marginTop = 30;
		valueAxis2.renderer.baseGrid.disabled = true;
		valueAxis2.renderer.inside = true;
		valueAxis2.renderer.labels.template.verticalCenter = "bottom";
		valueAxis2.renderer.labels.template.padding(2, 2, 2, 2);
		//valueAxis.renderer.maxLabelPosition = 0.95;
		valueAxis2.renderer.fontSize = "0.8em"

		valueAxis2.renderer.gridContainer.background.fill = am4core.color("#000000");
		valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;

		let series2 = chart.series.push(new am4charts.ColumnSeries());
		series2.dataFields.dateX = "date";
		series2.dataFields.valueY = "value";
		series2.yAxis = valueAxis2;
		series2.tooltipText = "{valueY.value}";
		series2.name = "MSFT: Volume";
		series2.tooltip.getFillFromObject = false;
		series2.tooltip.background.fill = am4core.color("#000000");
		series2.tooltip.background.fillOpacity = 1;
		// volume should be summed
		series2.groupFields.valueY = "sum";
		series2.defaultState.transitionDuration = 0;

		chart.cursor = new am4charts.XYCursor();

		// let scrollbarX = new am4charts.XYChartScrollbar();
		// scrollbarX.series.push(series);
		// scrollbarX.marginBottom = 20;
		// scrollbarX.scrollbarChart.xAxes.getIndex(0).minHeight = undefined;
		// chart.scrollbarX = scrollbarX;


		// Add range selector
		// let selector = new am4plugins_rangeSelector.DateAxisRangeSelector();
		// selector.container = document.getElementById("controls");
		// selector.axis = dateAxis;

		// let interval;
		// function startInterval() {
		// 		interval = setInterval(function() {
		// 				visits =
		// 						visits + Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
		// 				let lastdataItem = series.dataItems.getIndex(series.dataItems.length - 1);
		// 				chart.addData(
		// 					{ date: new Date(lastdataItem.dateX.getTime() + 1000), value: visits },
		// 					1
		// 			);
		// 		}, 1000);
		// }

		// startInterval();

		// bullet at the front of the line
		let bullet = series.createChild(am4charts.CircleBullet);
		bullet.circle.radius = 5;
		bullet.fillOpacity = 1;
		bullet.fill = chart.colors.getIndex(0);
		bullet.isMeasured = false;

		series.events.on("validated", function() {
				bullet.moveTo(series.dataItems.last.point);
				bullet.validatePosition();
		});

    // Add range selector
    // var selector = new am4plugins_rangeSelector.DateAxisRangeSelector();
    // selector.container = document.getElementById("rangeSelector");
    // selector.axis = dateAxis;

		chart = chart;

		return() => {
			if (chart) {
					chart.dispose();
			}
		}
	},[])


  return (
    <>
      <div id="liveBitcoin" className="height-500"></div>
      <div id="rangeSelector"></div>
    </>
  )
}

export default LiveChart
