import React, { useState, useEffect } from "react";
import FancyBox from "../Helper/FancyBox";


const DashboardLandingIndex = () => {
    return (
        <>
            <div className="main_bg ">
                <div className="container">
                    <div className="sixteen columns bottom_border">
                        <div className="eleven columns alpha logo_align ">
                            <a href="/" target="_blank" style={{ display: "inline-block" }} rel="nofollow">
                                <img src="" src={window.location.origin + "/assets/dashboard-landing/images/Logo.png"} width="150" height="auto" alt="ICO SCRIPT" style={{ filter: "invert(100%)" }} />
                            </a>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="sixteen columns">
                        <div className="welcome_font">ico<span style={{ color: "#7dc576" }}>D</span>ash</div>
                        <div className="Under_title">Enable your users to Buy your tokens - Sell your tokens using this powerful All-in-One ICO Script. The backend is connected with a LIVE exchange for Liquidity.
                        </div>
                        <div className="align_c">
                            <span className="submit_btn1 ">
                                <a href="https://bit.ly/2ZdJyC4" target="_blank" rel="nofollow">View Demo</a>
                            </span>
                            <span className="submit_btn1 orange">
                                <a href="https://bit.ly/2ZdJyC4" target="_blank" rel="nofollow">View Admin Panel</a>
                            </span>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="sixteen columns align_c box_img_s animate-in " data-anim-delay="100" data-anim-type="fade-in-right-large">
                        <img src={window.location.origin + "/assets/dashboard-landing/images/top1.png"} className="" alt="initial coin offering website template" width="500" />
                    </div>
                </div>
            </div>
            <ul className="breadcrumb">
                <li><a href="/" target="_blank" rel="nofollow">Token Creator</a></li>
                <li>Token Creator</li>
            </ul>
            <div className="feautres">
                <div className="container">
                    <div className="sixteen columns top_4">
                        <div className="four columns alpha align_c animate-in " data-anim-delay="100" data-anim-type="fade-in-up">
                            <div className="marge_">
                                <a href="javascript:void(0);">
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/12resp.png"} width="100" height="100" alt="ico dashboard script" className=""/>
                                </a>
                            </div>
                            <div className="titre_">
                                <span className="">Live Exchange</span>
                            </div>
                            <div className="text_">
                                <span>The platform is connected to a LIVE exchange in the BG. For each token BUY-SELL that happens on the frontend, an automated BUY-SELL happens in the exchange. Helps maintain automatic liquidity.
                                </span>
                            </div>
                        </div>
                        <div className="four columns align_c animate-in " data-anim-delay="100" data-anim-type="fade-in-down">
                            <div className="marge_">
                                <a href="javascript:void(0);">
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/12rubiks.gif"} width="100" height="100" alt="ico website script" className=""/> 
                                </a>
                            </div>
                            <div className="titre_">
                                <span className="">Fully Customizable</span>
                            </div>
                            <div className="text_">
                                <span>You get 100% source code. This powerful ico software is built on a easy to customize architecture.</span>
                            </div>
                        </div>
                        <div className="four columns align_c animate-in " data-anim-delay="100" data-anim-type="fade-in-up">
                            <div className="marge_">
                                <a href="javascript:void(0);">
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/12bootst.png"} alt="ico Script" width="100" height="100" className=""/> 
                                </a>
                            </div>
                            <div className="titre_">
                                <span className="">Auto Market Cap</span>
                            </div>
                            <div className="text_">
                                <span>Executes automated on-chain transactions via. a live DEX exchange. This helps increase your tokens Market Capitalisation & move up the charts on Crypto Comparison portals.</span>
                            </div>
                        </div>
                        <div className="four columns omega align_c animate-in " data-anim-delay="100" data-anim-type="fade-in-down">
                            <div className="marge_">
                                <a href="javascript:void(0);">
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/12ecomw.png"} width="100" height="100" alt="ico software" className=""/> 
                                </a>
                            </div>
                            <div className="titre_">
                                <span className="">Admin Panel</span>
                            </div>
                            <div className="text_">
                                <span>A secure and powerful Admin Panel for you to manage the entire ico portal. From Settings, User Management to Coin deposit, verification etc. has every module required to enable your ICO seamlessly. <br /><a href="#faq">View Screenshots</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="sixteen columns get_section" style={{textAlign: "center", fontSize: "20px", padding: "0 0 1em1", fontWeight: "bold"}}>
                        <p>Questions? Shoot a mail to: <a href="mailto:hello@tokencreator.space">hello@tokencreator.space</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="bg_color1 pad">
                <div className="container" style={{paddingTop: "50px", paddingBottom: "50px"}}>
                    <center>
                        <span>
                            <h3 style={{color: "#808080"}}>ICO Script Details</h3>
                        </span>
                    </center>
                    <div className="sixteen columns">
                        <br/>
                        <center>
                            <div className="seven columns alpha slow-mo">
                                <table className="zui-table zui-table-rounded curve">
                                    <tbody>
                                        <tr>
                                            <td>Version</td>
                                            <td>2.1</td>
                                        </tr>
                                        <tr>
                                            <td>Date Created</td>
                                            <td>20/02/2021</td>
                                        </tr>
                                        <tr>
                                            <td>Last Updated</td>
                                            <td>15/04/2021</td>
                                        </tr>
                                        <tr>
                                            <td>Node JS Version</td>
                                            <td>Updated to Latest</td>
                                        </tr>
                                        <tr>
                                            <td>React JS Version</td>
                                            <td>Updated to Latest</td>
                                        </tr>
                                        <tr>
                                            <td>Solidity Version</td>
                                            <td>Updated to Latest</td>
                                        </tr>
                                        <tr>
                                            <td>Mongo DB Version</td>
                                            <td>Latest</td>
                                        </tr>
                                        <tr>
                                            <td>Layout</td>
                                            <td>Fixed, Fluid, Responsive</td>
                                        </tr>
                                        <tr>
                                            <td style={{border: "none"}}>Compitability</td>
                                            <td style={{border: "none"}}>All Major Browsers</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </center>
                        <div className="nine columns omega">
                            <div className="align_c plat animate-in " data-anim-delay="100" data-anim-type="bounce-in-big" style={{paddingTop: "8%"}}>
                                <center>
                                    <label>
                                        <h4 className="framework_cls" style={{color: "#8c8c8c", fontWeight: "300"}}>Number of Hours taken to develop: 543 Hours</h4>
                                    </label>
                                </center>
                                <div style={{paddingTop: "40px"}}>
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/ICO-Script-Software-solidity.png"} className="img_pos align_r" width="100" height="auto" style={{position: "relative", top: "-1em;"}} alt="ico dashboard template"/>
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/ICO-Script-react.png"} className="img_pos align_c" width="100" height="auto" style={{position: "relative", top: "-1em;"}} alt="ico dashboard template" style={{position: "relative", top: "-1em;"}}/>
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/ICO-Software-Script-Node.png"} className=" align_l" width="100" height="auto" alt="ico dashboard template"/>
                                    <img src={window.location.origin + "/assets/dashboard-landing/images/cryptocurrency-wallet-script.png"} className=" align_l" width="100" height="auto" alt="ico dashboard template"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg_color4 " id="view-demo">
                <div className="container">
                    <center className="
                    caption-head">
                        <label>
                            <span style={{fontSize: "2em", color: "#808080"}}><b>Top 4 reasons to choose icodash script:</b></span>
                        </label>
                    </center>
                    <br></br>
                    <h5>Built-in Exchange for your tokens</h5>
                    <p>
                        While a majority of ICO Softwares in the market only gives your users options to BUY your tokens, this platform offers the SELL function as well. Integrated in the script is a middle-tier layer that makes your ICO dashboard act as a live crypto exchange for your tokens. Following are the advantages of this integration:
                    </p>
                    <p>
                        - The middle-tier helps in managing liquidity automatically for your tokens ie. It fills your dashboard automatically with your custom tokens, so users can BUY. Also tops up your dashboard automatically with other crypto like ETH, BNB etc. for users to SELL your token.<br />
                        - Also present are options to turn ON-OFF the above exchange option. In case you decide you want to only have the BUY option, you can just turn off the live exchange instantly using the secure Admin Panel.<br />
                    </p>
                    <h5>ATM ready</h5>
                    <p>The entire ICO script is made to be integrated in any ATM machine. The front-end design automatically fits the ATM machine screen size. The platform comes ready with a secure API layer to connect any ATM's money teller module.
                    </p>
                    <p>
                        You can order any ATM machine in the market > Load this platform on the screen > choose the payment methods to enable ( ex: Crypto, Credit Card, Cash etc. ). Users would be able to Buy-Sell your tokens on the ATM machine.
                        And since there is a live exchange connected in the BG, your ATM machines liquidity will be auto-filled both ways.
                    </p>
                    <h5>Increases your Token's Market Cap</h5>
                    <p>
                        We understand how important it is to increase the market capitalisation of your token. This fascinating ICO dashboard script helps to achieve that. Thanks to the smart contract on the platform. Any BUY-SELL that your users do to your token, executes a BUY-SELL on the connected DEX exchange in the BG. These transactions get captured in real-time on-chain thus boosting your tokens daily transaction volume. This helps to get your token listed on portals like CoinMarketCap, CoinGekho, CryptoCompare etc. automatically.
                    </p>
                    <h5>Live market price updates for your Token</h5>
                    <p>
                        This All-in-One ICO script also has provision to update your token price in real-time from the market. The built-in API's can used to instantly hook your token's price to real-time market values. If you desire to manually set the price of your token, you can just flip a switch in the admin panel and set a price as well.
                    </p>
                </div>
            </div>
            <div className="bg_color1" id="investor">
                <div className="container">
                    <div className="sixteen columns">
                        <div className="check_font ">Let investors start Trading your coins Today!</div>
                        <div className="eight columns alpha animate-in slow-mo " data-anim-delay="100" data-anim-type="zoom-in-left-large">
                            <div className="two columns alpha ">
                                <div className=" circular_img ">
                                    <a href="javascript:void(0);">
                                        <img src={window.location.origin + "/assets/dashboard-landing/Points_images/1.png"} alt="ico dashboard script" className=""/> 
                                    </a>
                                </div>
                            </div>
                            <div className="five columns omega">
                                <div className=" titre_1">Admin Panel</div>
                                <div className=" text_1">Comes with a Secure and Powerful Admin Panel to manage all your User Registrations and KYC verification. Also has the ability to Reject / Approve Users along with instant notifications.
                                    <a href="#faq">
                                        <b style={{color: "#c70a0a", fontWeight: "600"}}>View screenshots</b>
                                    </a>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="two columns alpha ">
                                <div className=" circular_img">
                                    <a href="javascript:void(0);">
                                        <img src={window.location.origin + "/assets/dashboard-landing/Points_images/2.png"} alt="ico website script" className=""/> 
                                    </a>
                                </div>
                            </div>
                            <div className="five columns omega ">
                                <div className=" titre_1">Installation manual</div>
                                <div className=" text_1">This ico script is well documented and supported, you're never going to be stuck.</div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="two columns alpha ">
                                <div className=" circular_img">
                                    <a href="javascript:void(0);" target="_blank">
                                        <img src={window.location.origin + "/assets/dashboard-landing/Points_images/3.png"} alt=" ico Script" className=""/> 
                                    </a>
                                </div>
                            </div>
                            <div className="five columns omega ">
                                <div className=" titre_1"> 100% Source Code</div>
                                <div className=" text_1">You get 100% un-encrypted source code, to customize at your will. Add/ Edit any feature easily.</div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="two columns alpha ">
                                <div className=" circular_img">
                                    <a href="javascript:void(0);">
                                        <img src={window.location.origin + "/assets/dashboard-landing/Points_images/4.png"} alt="ico software" className=""/> 
                                    </a>
                                </div>
                            </div>
                            <div className="five columns omega ">
                                <div className=" titre_1">Announcement Module</div>
                                <div className=" text_1">An exclusive module for you to announce and broadcast anything to your users. Will reach them directly via. their Dashboard. This amazing ico script also sends email notifications accordingly..</div>
                            </div>
                        </div>
                        <div className="eight columns omega box_img ">
                            <img src={window.location.origin + "/assets/dashboard-landing/images/second2.png"} alt="ico dashboard template" className="animate-in " data-anim-delay="100" data-anim-type="fade-in-left-large"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="bg_color2">
                <div className="container">
                    <div className="sixteen columns">
                        <div className="titre_2">The best way to Predict the Future is to Create it!</div>
                        <div className="top_2">
                            <span className="text_2">
                                <font color="#ffffff">In every business, only the best Win; Meticulous, Dedicated and Fast. If that's you, it's time to look the part.</font>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="bg_color2 survival-otr ">
                <div className="container">
                    <div className="sixteen columns">
                        <div className="eight columns alpha box_img1 ">
                            <img src={window.location.origin + "/assets/dashboard-landing/images/15.png"} className="go_live animate-in " data-anim-delay="100" data-anim-type="fade-in-right-large" alt="ico script"/>
                        </div>
                        <div className="eight columns omega ">
                            <div className="bg_color3 animate-in " data-anim-delay="100" data-anim-type="fade-in-left-large">
                                <div className="titre_3">Plug &amp; Play</div>
                                <div className="text_3 ">
                                    <span className="">Using the API layer, this Super Secure ICO script can be easily accommodate any coin or token.</span>
                                </div>
                                <div className="clearfix"></div>
                                <div className="button_div">
                                    <div className="create_font">Developed by
                                        <a href="/" target="_blank" rel="nofollow">
                                            <font color="#ffffff">Token Creator</font>
                                        </a>
                                    </div>
                                    <div className="submit_btn3">
                                    </div>
                                </div>
                            </div>
                            <div className="avail_font">
                                <span className="">
                                    <font color="#ffffff">Supported Browsers</font>
                                </span>
                            </div>
                            <div className="align_c plat animate-in " data-anim-delay="100" data-anim-type="bounce-in-big">
                                <img src={window.location.origin + "/assets/dashboard-landing/images/browicall.png"} className="img_pos align_r brows" width="300" height="64" alt="ico dashboard script"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="sixteen columns" style={{paddingBottom: "25px"}} id="download">
                <div className="Under_title" style={{paddingBottom: "0px"}}>
                    <span>
                        <img src={window.location.origin + "/assets/dashboard-landing/essentials/fb_icon.png"} width="15" style={{position: "relative", top: "3px"}} alt="ico Script"/>
                        <span className="recommend_ppl">1,507 people recommend this.</span>
                    </span>
                </div>
                <div className="recent_news_item second_table">
                </div>
                <center>
                    <label>
                        <div id="threereasons"></div>
                    </label>
                </center>
            </div>
            <div className="bg_color1" id="version-changes" style={{paddingTop: "50px"}}>
                <div className="container">
                    <h3 className="center-align">Changelog</h3>
                    <ul className="acc-list">
                        <li>
                            <span>See what's new</span>
                            <ul className="hidden">
                                <li>
                                    <p><b className="text-success">Upcoming Features:</b></p>
                                    <p>
                                        - Enhanced GDPR settings<br />
                                        - Balance Sheet generation
                                    </p>
                                    <p><b className="text-success">Version 2.1 </b>
                                    </p>
                                    <p>
                                        - The all new All-in-One ICO Dashboard launched.<br />
                                        - Includes support for real-time Buy-Sell of custom tokens.<br />
                                        - Robust API layers to connect to exchanges, market capitalization platforms.<br />
                                        - New secure Admin dashboard that can be configured as clients desire.<br />
                                    </p>
                                    <p><b className="text-success">Version 1.7 </b><br />
                                        Dividend Dashboard and Payouts
                                    </p>
                                    <p><b className="text-success">Version 1.6.1 </b><br />
                                        Squashed a minor bug in shareholder voting module.
                                    </p>
                                    <p><b className="text-success">Version 1.6</b><br />
                                        Released date: 6th, December, 2018
                                    </p>
                                    <p>
                                        - Shareholder voting <br />
                                        <em> Involve your shareholders (token buyers), ask them their opinion before taking important decisions related to the company/project. </em><br />
                                        - Income/Expense tracking <br />
                                        <em>Add, track your expenses and income, download reports</em><br />
                                        - Design related bug fixes
                                        <em> We fixed some design bugs that came up after the previous update</em><br />
                                        - Improved admin panel design <br />
                                        <em> With emphasize on typography &amp; consistency </em><br />
                                        - Improved user experience for Token buyers<br />
                                        - Schedule email campaigns based on time and date<br />
                                        <em>
                                            You can schedule and send email campaigns now on any particular day and time.
                                        </em><br />
                                        - Transaction data is now more clear in the admin panel.
                                    </p>
                                </li>
                                <li>
                                    <p><b className="text-success">Version 1.5</b></p>
                                    <p>
                                        - We're refreshing a lot of features and adding some refined ones in the upcoming update. <br />
                                        - The dashboard shows the key metrics<br />
                                        - Token Delivery Method (Automatic and Semi-automatic)
                                        <em>Automatic payment methods work with PayPal and stripe, once someone successfully makes a transaction, the tokens are sent automatically without manual intervention. Semi-automatic requires you to manually send the tokens after receiving the payment, supported payment options. BTC, ALTcoin, Paypal and Bank transfer.</em><br />
                                        - Redesigned timeline functionality, Timeline now shows token price change. <br />
                                        You can set a date on which the token price would change, based on it the price changes and stays till another price change event occurs. <br />
                                        - Notify user of the price change before X days. <br />
                                        - <b>Marketing Suite </b><br />
                                        <em>Now you can create email and SMS Campaigns and send them to your users based on those who purchased tokens, who have not or to anyone registered on your ICO platform</em><br />
                                        - Mailgun and Twilio integration<br />
                                        - Campaign reports <br />
                                        - GDPR Compliance
                                    </p>
                                    <p>
                                        <b className="text-success">Version 1.4</b>
                                    </p>
                                    <p>- Redesigned the ICO timeline progress <br />bar- Added 'Mark as achieved' to display as completed.</p>
                                    <p><b className="text-success">Version 1.3</b></p>
                                    <p>- ERC20 Dashboard integration<br />
                                        - Notification alerts issue fixed<br />
                                        - The ICO launch date countdown can be configured in the admin panel and it will display on the default landing page.<br />
                                        - The whitepaper can be uploaded from the admin panel</p>
                                    <p><b className="text-success">Version 1.2</b></p>
                                    <p>
                                        - Updated dependencies to the latest version<br />
                                        - Fixed compatibility issues with the upgrade.<br />
                                        - Admin panel redesign<br />
                                        - Updated the Token purchase User side experience<br />
                                        - Added a dynamic Token price calculator
                                    </p>
                                    <p><b className="text-success">Version 1.1</b></p>
                                    <p>- Menu bug fixes <br />
                                        - Kyc storage file path issue fixed.<br />
                                        - Export user data on CSV, PDF and Microsoft Excel.
                                    </p>
                                    <p><b className="text-success">Version 1.0</b></p>
                                    <p>
                                        1. Display payment methods (Paypal, BTC, bank wire and ALT coins)<br />
                                        2. Alert Notification for users<br />
                                        3. ICO event timeline<br />
                                        4. alert emails <br />
                                        5. Upload KYC documentation<br />
                                        6. View referral points<br />
                                        7. View referral link</p>
                                    <p> <b className="text-success">Admin panel</b><br />
                                        8. Moderate users from admin panel<br />
                                        9. View transactions for individual users <br />
                                        10. Send coins manually using Metamask<br />
                                        11. Add payment details from admin panel<br />
                                        12. Add Smart contract details<br />
                                        13. Track coin usage<br />
                                        14. Set notifications and alerts<br />
                                        15. Add events to display on the timeline
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="faq-section" id="faq">
                <center className="check_font faq_heading">Frequently Asked Questions about this Template</center>
                <div className="container new faq_container" id="cssmenu">
                    <div className="sixteen columns">
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="eight columns">
                                
                                    <span className="que">How to integrate my Coin / Token into this ico script?</span>
                                    <ul id="ans1" className="absolute-details">
                                        <p>This incredible ico script comes with a robust API layer.Using the API layer, you can integrate any coin or Token. If your Token is an ERC20 token, its all the more easier to integrate.</p>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="eight columns">
                                    <span className="que">How secure is this turn-key ico script?</span>
                                    <ul id="ans2" className="absolute-details">
                                        <p>We have launched a lot many ICO's for customers. And obviously Security is of top most importance. Or the coins transferred to your customers can get easily hacked. Remember that all the security you do on the ICO dashboard / Admin Panel side is only to prevent the hacker from entering in here. A majority of the hacks happen actually on your customers side after the coins have been transferred. Believe us, we have been there and seen that.</p>
                                        <p>Based on experts feedback and experience, we have created a permission based semi-automatic method to this entire ico script. This method is the most secure approach to ICO. Will give you the peace of mind to take care of your business in full focus ( without bothering about security ).</p>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="eight columns">
                                    <span className="que">Which coin do you recommend for ICO. Why?</span>
                                    <ul id="ans4" className="absolute-details">
                                        <li>
                                            <p>
                                                We get this question often. We strongly recommend to go with an Ethereum framework based ICO ( ERC20 ). The main reasons being:
                                            </p>
                                            <p>- Security: Once your users have bought tokens / coins from you > You can just transfer the coins to their Ethereum Wallet. Period. You need to least worry about security, as Ethereum takes care from then on ( as its their wallet system ). Its like the U.S treasury taking care of your funds! This is not the case if you create your coins using any other fork. You will need to ask your customers to have a custom wallet and they should hold the coins in that wallet. This is a serious problem.</p>
                                            <p>- Exchange listing: If your coins are based on the ERC20 standards, every exchange can easily integrate your coins into their system ( as it is a global standard ).</p>
                                            <p>- Smart contracts: Etheruem is becoming the default for writing smart contracts for any project. If your coins are ERC20, it becomes easy for any developer to integrate your coins in their smart contract for a variety of reasons.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="eight columns">
                                    <span className="que">Does this ico script come with a ico landing page?</span>
                                    <ul id="ans5" className="absolute-details">
                                        <p>Yes. This powerful ico script comes with an elegant looking landing page. You can customize this landing page as per your brand. You can also easily remove this landing page alone and bring in your custom template there.</p>
                                        <p><b>Note:</b> The template you see in the DEMO is not the template you will be getting ( as its done for another customer ). You will be getting another template. You can check the template <a href="/ico-script-software/demo-landing/" target="_blank" rel="nofollow" style={{color: "#fff", fontWeight: "600"}}>HERE</a></p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="aniimated-thumbnials">
                        <FancyBox>
                        <a href="/assets/dashboard-landing/images/ss1.png"  data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss1.png"} alt="ico website script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss2.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss2.png"} alt="ico Script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss3.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss3.png"} alt="ico Software" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss4.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss4.png"} alt="ico dashboard script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss5.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss5.png"} alt="ico website script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss6.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss6.png"} alt="ico Script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss7.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss7.png"} alt="ico Software" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss8.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss8.png"} alt="ico dashboard script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss9.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss9.png"} alt="ico website script " />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss10.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss10.png"} alt="ico Script" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss11.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss11.png"} alt="ico Software" />
                        </a>
                        <a href="/assets/dashboard-landing/images/ss12.png" data-fancybox>
                            <img src={window.location.origin + "/assets/dashboard-landing/images/ss12.png"} alt="ico website script" />
                        </a>
                        </FancyBox>
                    </div>
                </div>
            </div>
            <span id="myBtn">
                <span className="submit_btn1 ">
                    <a href="https://bit.ly/2ZdJyC4" target="_blank" rel="nofollow">View Demo</a>
                </span>
                <span className="submit_btn1 orange">
                    <a href="https://bit.ly/2ZdJyC4" target="_blank" rel="nofollow">View Admin Panel</a>
                </span>
                <span className="submit_btn2">
                </span>
            </span>
            <div className="fb_chat">
                <a href="https://www.messenger.com/t/104425381888590" className="fb-msg-btn-chat" target="_blank" rel="nofollow"> Contact us on Facebook</a>
            </div>
            <div className="fb_chat twitter">
                <a href="mailto:hello@tokencreator.space" className="fb-msg-btn-chat tweet"> <span>-or-</span>&nbsp;via. Email
                </a>
            </div>
        </>
    );
}

export default DashboardLandingIndex;