import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TermCondition from "./TermCondition";
import { connect } from "react-redux";
import { saveTokenStart } from "../store/actions/TokenAction";
import TokenDecimalModal from "../landing/createtoken/TokenDecimalModal";
import TokenHelperModal from "../landing/createtoken/TokenHelperModal";

const CreateToken = (props) => {

  const [createTokenInput, setCreateTokenInput] = useState({
    name: "",
    token_symbol: "",
    decimal_points: "",
    max_token_supply: "",
    tokenContractAddress: "",
    access_type: "None",
    transfer_type: "Unstoppable",
    remove_copyrights: false,
    burnable: false,
    mintable: false,
    network_type: "Binance",
  });

  const [isAgreementApproved, setIsAgreementApproved] = useState(false);

  const createTokenschema = Yup.object().shape({
    name: Yup.string().required("Token name is required"),
    token_symbol: Yup.string().required("Token symbol is required"),
    decimal_points: Yup.number().required("Token decimal points is required")
      .moreThan(0, 'should not be zero or less than zero'),
    max_token_supply: Yup.number().required("Token total is required")
      .moreThan(0, 'should not be zero or less than zero'),
    access_type: Yup.string().required("Access type is required"),
    transfer_type: Yup.string().required("transfer type is required"),
    remove_copyrights: Yup.boolean(),
    burnable: Yup.boolean(),
    mintable: Yup.boolean(),
    network_type: Yup.string().required("network type is required"),
    token_type: Yup.string().required("Token Type is required"),
    other_token_type: Yup.string().when("token_type", {
      is: (token_type) => token_type == "others",
      then: Yup.string().required("Other Token is required"),
      otherwise: Yup.string(),
    }),
    fund_raise: Yup.number().required("Required *")
      .moreThan(0, 'should not be zero or less than zero'),
    one_token_price: Yup.number().required("Required *")
      .moreThan(0, 'should not be zero or less than zero'),
    offering: Yup.array().min(1, "Required *"),
    // wallet_address: Yup.string().required("Wallet Address is required *")
  });

  const handleSubmit = async (values) => {
    if (isAgreementApproved) {
      setCreateTokenInput(values);
      props.dispatch(
        saveTokenStart({
          ...values,
          description: "Some description static",
          initial_token_supply: "10000",
        })
      );
    }
  };

  const validateTokenName = (value) => {
    let error;
    if (value.trim() !== value) {
      error = 'Invalid token name';
    }
    return error;
  }

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>Create Token</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Tokens</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Create Token</a>
            </li>
          </ol>
        </section>
        <section className="">
          <div className="container-fluid">
            <div class="">
              <Formik
                initialValues={{
                  name: "",
                  token_symbol: "",
                  decimal_points: "",
                  max_token_supply: "",
                  tokenContractAddress: "",
                  access_type: "None",
                  transfer_type: "Unstoppable",
                  remove_copyrights: false,
                  burnable: false,
                  mintable: false,
                  network_type: "Binance",
                  token_type: "equity",
                  other_token_type: "",
                  fund_raise: "",
                  one_token_price: "",
                  offering: [],
                  // wallet_address: ""
                }}
                className="w-100"
                validationSchema={createTokenschema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form noValidate>
                    <div className="container-fluid">
                      <div className="custom-box p-3 create-token-width">
                        <div className="row">
                          <div className="col-12">
                            <p className="mb-0">
                              Using this tool, you can: <br />
                              <ul className="afterLogin-ul">
                                <li>
                                  Easily create any number of Coins / Tokens /
                                  Cryptocurrency in just a few clicks.
                                </li>
                                <li>
                                  Effortlessly create any type of Tokens:
                                  Utility Tokens for your ICO, Security Tokens
                                  for your STO, Asset Backed Tokens ( ABT ) etc.
                                </li>
                                <li>
                                  Tokenize any of your real world assets ( Real
                                  Estate, Art, Agriculture etc. ) in a few
                                  clicks.
                                </li>
                                <li>
                                  Create Tokens that has all the regulatory
                                  compliance of your Jurisdiction built in.
                                </li>
                              </ul>
                              <a
                                target="_blank"
                                className="text-bold"
                                href="https://blog.gatecoin.com/why-asset-backed-tokens-will-be-mainstream-56f4aad704c4"
                              >
                                {" "}
                                Learn More.
                              </a>
                            </p>
                            <br />
                            <h3 className="text-bold m-0 text-center white-color mb-3">
                              IF YOU ARE READY, LETS START WITH THE BASIC
                              DETAILS
                            </h3>
                          </div>
                          <div className="col-lg-12 p-0">
                            <div className="custom-box p-0 create-token-width">
                              <div className="createToken-header">
                                <h3 className="text-bold m-0 whitecolor">
                                  Token Details
                                </h3>
                              </div>
                              <div className="form-wrapper p-3">
                                <div className="row">
                                  <div class="form-group col-lg-4">
                                    <h5>
                                      Token name{" "}
                                      <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="name"
                                        placeholder="Token Name"
                                        className={`no-padding form-control ${touched.name && errors.name
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        validate={validateTokenName}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="name"
                                        className="invalid-feedback mt-3"
                                      />
                                      <p className="mb-0 mt-2 text-muted">
                                        Choose a name for your token.
                                      </p>
                                    </div>
                                  </div>
                                  <div class="form-group col-lg-4">
                                    <h5>
                                      Your Coin/ Token abbreviation{" "}
                                      <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="token_symbol"
                                        placeholder="Token Symbol"
                                        className={`no-padding form-control ${touched.token_symbol &&
                                          errors.token_symbol
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="token_symbol"
                                        className="invalid-feedback mt-3"
                                      />
                                      <p className="mb-0 mt-2 text-muted">
                                        ( Ex: BTC, EOS, ETH, XLM etc. )
                                      </p>
                                    </div>
                                  </div>
                                  <div class="form-group col-lg-4">
                                    <h5>
                                      Token Decimals{" "}
                                      <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="decimal_points"
                                        placeholder="Token Decimals"
                                        className={`no-padding form-control ${touched.decimal_points &&
                                          errors.decimal_points
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="decimal_points"
                                        className="invalid-feedback mt-3"
                                      />
                                      <p className="mb-0 mt-2 text-muted">
                                        Decimals * (Maximum number of decimals)?
                                        <a
                                          href="javascript:void(0)"
                                          data-toggle="modal"
                                          data-target="#tokenDecimal"
                                          className="white-color text-bold ml-2"
                                        >
                                          What is this?
                                        </a>
                                      </p>
                                    </div>
                                  </div>

                                  <div class="form-group col-lg-4">
                                    <h5>
                                      Total Supply{" "}
                                      <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="number"
                                        name="max_token_supply"
                                        placeholder="Token Supply"
                                        className={`no-padding form-control ${touched.max_token_supply &&
                                          errors.max_token_supply
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="max_token_supply"
                                        className="invalid-feedback mt-3"
                                      />
                                      <p className="mb-0 mt-2 text-muted">
                                        (Max number of coins to be created)
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div class="form-group col-lg-4">
                                    <h5>
                                      Wallet Address{" "}
                                      <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="wallet_address"
                                        placeholder="Wallet Address"
                                        className={`no-padding form-control ${touched.wallet_address &&
                                          errors.wallet_address
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="wallet_address"
                                        className="invalid-feedback mt-3"
                                      />
                                      <p className="mb-0 mt-2 text-muted">
                                        (Your crypto wallet address)
                                      </p>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 p-0 ">
                            <div className="row no-gutters">
                              <div className="col-lg-4 p-0">
                                <div className="custom-box p-0 create-token-width ">
                                  <div className="createToken-header">
                                    <h3 className="text-bold m-0 whitecolor">
                                      Token Features
                                    </h3>
                                  </div>
                                  <div className="form-wrapper p-3">
                                    <div class="form-group">
                                      <h5>
                                        Access Type{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <select
                                          name="access_type"
                                          id="access_type"
                                          className={`${touched.access_type &&
                                            errors.access_type
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "access_type",
                                              event.target.value
                                            );
                                          }}
                                        >
                                          <option value="none">None</option>
                                          <option value="ownable">
                                            Ownable
                                          </option>
                                          <option value="role_based">
                                            Role Based
                                          </option>
                                        </select>
                                        <ErrorMessage
                                          component="div"
                                          name="access_type"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          None, Ownable, Role Based.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <h5>
                                        Transfer Type{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <select
                                          name="transfer_type"
                                          id="transfer_type"
                                          className={`${touched.transfer_type &&
                                            errors.transfer_type
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "transfer_type",
                                              event.target.value
                                            );
                                          }}
                                        >
                                          <option value="Unstoppable">
                                            Unstoppable
                                          </option>
                                          <option value="Pausable">
                                            Pausable
                                          </option>
                                        </select>
                                        <ErrorMessage
                                          component="div"
                                          name="transfer_type"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Unstoppable, Pausable
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 p-0">
                                <div className="custom-box p-0 create-token-width">
                                  <div className="createToken-header">
                                    <h3 className="text-bold m-0 whitecolor">
                                      Token Type and Network
                                    </h3>
                                  </div>
                                  <div className="form-wrapper p-3">
                                    <div class="form-group">
                                      <h5>
                                        Coin should be based on{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <select
                                          name="network_type"
                                          id="network_type"
                                          className={`${touched.network_type &&
                                            errors.network_type
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "network_type",
                                              event.target.value
                                            );
                                          }}
                                        >
                                          <option value="Binance">
                                            Binance(recommended)
                                          </option>
                                          <option value="Polygon">
                                            Polygon
                                          </option>
                                          <option value="Ethereum">
                                            Ethereum
                                          </option>
                                        </select>
                                        <ErrorMessage
                                          component="div"
                                          name="network_type"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Choose your Network.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        What are you looking to Tokenize?{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <select
                                          name="token_type"
                                          id="token_type"
                                          className={`input-bordered ${touched.token_type &&
                                            errors.token_type
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "token_type",
                                              event.target.value
                                            );
                                            setCreateTokenInput({
                                              ...createTokenInput,
                                              [event.target.name]:
                                                event.target.value,
                                            });
                                          }}
                                        >
                                          <option value="equity">
                                            Equity Token
                                          </option>
                                          <option value="asset">
                                            Asset Token
                                          </option>
                                          <option value="stable">
                                            Stable Coin
                                          </option>
                                          <option value="utility">
                                            Utility Token
                                          </option>
                                          <option value="others">others</option>
                                        </select>
                                        <ErrorMessage
                                          component="div"
                                          name="token_type"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Choose the type of token you are
                                          trying to create (Utility, Asset,
                                          Equity)
                                          <a
                                            className="white-color text-bold"
                                            target="_blank"
                                            href="https://www.bitcoinmarketjournal.com/equity-token/"
                                          >
                                            {" "}
                                            Learn more
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                    {createTokenInput.token_type ==
                                      "others" && (
                                        <div class="form-group">
                                          <h5 className="black-text">
                                            Please define your token type{" "}
                                            <span class="text-danger">*</span>
                                          </h5>
                                          <div class="controls">
                                            <Field
                                              type="text"
                                              name="other_token_type"
                                              placeholder="Your Token Type"
                                              className={`no-padding form-control ${touched.other_token_type &&
                                                errors.other_token_type
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="other_token_type"
                                              className="invalid-feedback mt-3"
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 p-0">
                                <div className="custom-box p-0 create-token-width">
                                  <div className="createToken-header">
                                    <h3 className="text-bold m-0 whitecolor">
                                      Extra features
                                    </h3>
                                  </div>
                                  <div className="form-wrapper p-3">
                                    <div className="form-group">
                                      <div className="controls">
                                        <div className="custom-checkbox mb-3">
                                          <Field
                                            className="switch"
                                            type="checkbox"
                                            name="remove_copyrights"
                                          />
                                          <h5 className="ml-3 mb-0">
                                            Remove Copyright
                                          </h5>
                                        </div>
                                        <p className="mb-0 mt-2 text-muted">
                                          Remove the link pointing to this page
                                          from your contract.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="controls">
                                        <div className="custom-checkbox mb-3">
                                          <Field
                                            className="switch"
                                            type="checkbox"
                                            name="burnable"
                                          />
                                          <h5 className="ml-3 mb-0">
                                            Burnable
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="controls">
                                        <div className="custom-checkbox mb-3">
                                          <Field
                                            className="switch"
                                            type="checkbox"
                                            name="mintable"
                                          />
                                          <h5 className="ml-3 mb-0">
                                            Mintable
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 p-0">
                            <div className="row no-gutters">
                              <div className="col-lg-8">
                                <div className="custom-box p-0 create-token-width">
                                  <div className="createToken-header">
                                    <h3 className="text-bold m-0 whitecolor">
                                      Tokenizer
                                    </h3>
                                  </div>
                                  <div className="form-wrapper p-3">
                                    <div className="row no-gutter">
                                      <div className="col-lg-6">
                                        <div class="form-group">
                                          <h5 className="black-text">
                                            How much funding are you aiming to
                                            raise?{" "}
                                            <span class="text-danger">*</span>
                                          </h5>
                                          <div class="custom-input-group-dashboard mb-3">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text">
                                                $
                                              </span>
                                            </div>
                                            <div class="controls w-100">
                                              <Field
                                                type="number"
                                                name="fund_raise"
                                                placeholder=""
                                                className={`no-padding form-control ${touched.fund_raise &&
                                                  errors.fund_raise
                                                  ? "is-invalid"
                                                  : ""
                                                  }`}
                                              />
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            component="div"
                                            name="fund_raise"
                                            className="invalid-feedback mt-3"
                                          />
                                        </div>
                                        <div class="form-group">
                                          <h5 className="black-text">
                                            What should be the price of one
                                            token?{" "}
                                            <span class="text-danger">*</span>
                                          </h5>
                                          <div class="custom-input-group-dashboard mb-3">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text">
                                                $
                                              </span>
                                            </div>
                                            <div class="controls w-100">
                                              <Field
                                                type="number"
                                                name="one_token_price"
                                                placeholder=""
                                                className={`no-padding form-control ${touched.one_token_price &&
                                                  errors.one_token_price
                                                  ? "is-invalid"
                                                  : ""
                                                  }`}
                                              />
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            component="div"
                                            name="one_token_price"
                                            className="invalid-feedback mt-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div class="form-group">
                                          <h5 className="black-text">
                                            In which parts of the world will you
                                            be marketing your offering?{" "}
                                            <span class="text-danger">*</span>
                                          </h5>
                                          <div className="controls">
                                            <div
                                              role="group"
                                              aria-labelledby="checkbox-group"
                                            >
                                              <ul className="custom-checkbox-group">
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-1"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Asia"
                                                    />
                                                    <label for="rdi-1">
                                                      Asia
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-2"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="USA"
                                                    />
                                                    <label for="rdi-2">
                                                      USA
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-3"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Canada"
                                                    />
                                                    <label for="rdi-3">
                                                      Canada
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-4"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Europe"
                                                    />
                                                    <label for="rdi-4">
                                                      Europe
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-5"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Australia"
                                                    />
                                                    <label for="rdi-5">
                                                      Australia
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-6"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Latin America"
                                                    />
                                                    <label for="rdi-6">
                                                      Latin America
                                                    </label>
                                                  </div>
                                                </li>
                                                <li className="">
                                                  <div className="field-wrap checkbox">
                                                    <Field
                                                      id="rdi-7"
                                                      name="offering"
                                                      type="checkbox"
                                                      value="Africa"
                                                    />
                                                    <label for="rdi-7">
                                                      Africa
                                                    </label>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <ErrorMessage
                                              component="div"
                                              name="offering"
                                              className="invalid-feedback mt-3"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="col-lg-12 p-0">
                                  <div className="custom-box p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h3 className="text-bold m-0 whitecolor">
                                        Agreement
                                      </h3>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div className="checkbox">
                                        <input
                                          type="checkbox"
                                          id="agreement"
                                          checked={isAgreementApproved}
                                          onChange={() => setIsAgreementApproved(!isAgreementApproved)} />
                                        <label htmlFor="agreement">
                                          I have read, understood and agreed to
                                          ERC20 Token Generator's{" "}
                                          <a
                                            href="#"
                                            className="text-bold white-color"
                                            data-toggle="modal"
                                            data-target="#termscondition"
                                          >
                                            Terms of Use
                                          </a>
                                          .
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 p-0">
                                  <div className="custom-box p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h3 className="text-bold m-0 whitecolor">
                                        Transaction
                                      </h3>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      {/* <div className="spliter mb-3">
                                      <div className="row">
                                        <div className="col-8">
                                          <h4 className="whitecolor text-bold">
                                            Commission Fee :
                                          </h4>
                                        </div>
                                        <div className="col-4">
                                          <h5>0.05 ETH</h5>
                                        </div>
                                      </div>
                                    </div> */}
                                      <div className="spliter">
                                        <div className="row">
                                          <div className="col-8">
                                            <h4 className="whitecolor text-bold">
                                              Gas Fee :
                                            </h4>
                                          </div>
                                          <div className="col-4">
                                            <h5>0.0001123 ETH</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 p-0 ">
                            <div className="row no-gutters">
                              <div className="d-flex justify-content-center my-3 w-100">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-medium withTheme ml-sm-3 mt-3 mt-md-0 text-bold"
                                  disabled={props.saveToken.buttonDisable || !isAgreementApproved}
                                >
                                  {props.saveToken.loadingButtonContent !== null
                                    ? props.saveToken.loadingButtonContent
                                    : "Create Token"}
                                </button>
                                <button
                                  type="button"
                                  className="btn no-btn p-3 white-color"
                                  disabled={props.saveToken.buttonDisable}
                                  data-toggle="modal"
                                  data-target="#tokenHelper"
                                >
                                  Need Help?
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
      <TermCondition />
      <TokenDecimalModal />
      <TokenHelperModal />
    </>
  );
};

const mapStateToPros = (state) => ({
  saveToken: state.token.saveToken,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateToken);
