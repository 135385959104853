import React, { useEffect, useState, useContext } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { connect } from "react-redux";
import {
  fetchPaymentDetailsStart,
  fetchSingleTokenStart,
  saveTokenPaymentAutoStart,
} from "../../store/actions/TokenAction";
import { Redirect, useParams } from "react-router";
import Web3 from "web3";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import price from "crypto-price";
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { useWeb3React } from "@web3-react/core";
import { authContext } from "../../auth/authprovider/AuthProvider";
import { SupportedChainId  , CHAIN_INFO} from "../../auth/authprovider/chains";
import ConnectWalletModal from "../../auth/ConnectWalletModal";
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  StoreHelpers,
  EVENTS,
  ACTIONS,
} from "react-joyride";

const CreateTokenAdminComission = (props) => {
  const { token_unique } = useParams();
  const context = useWeb3React();
  const { chainId, account, active } = context;
  const { auth, setAuth, hanldeLogout } = useContext(authContext);

  useEffect(() => {
    props.dispatch(
      fetchSingleTokenStart({ crypto_token_unique_id: token_unique })
    );
    props.dispatch(fetchPaymentDetailsStart());
    // get amount api call.
    // get the wallet address api call.
    setCryptoPayment();
  }, []);

  const etherNetID = 1; // mainnet-  1 // test - 1

  const binanceNetID = SupportedChainId.BINANCE; // mainnet - 56 // test = 97

  const polygonNetID = SupportedChainId.POLYGON; // mainnet - 137 // test = 80001

  const velasNetID = SupportedChainId.VELAS;

  const [connectMetaMask, setConnectMetaMask] = useState(false);

  const [loadingBlockchain, setLoadingBlockchain] = useState(true);

  const [loading, setLoading] = useState(true);

  //const [account, setAccount] = useState("");

  const [ethBalance, setEthBalance] = useState("0");

  const [crytoSymbol, setCrytoSymbol] = useState("ETH");

  const [metamaskNetStatus, setMetamaskNetStatus] = useState("");

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [etherAmount, setEtherAmount] = useState("0");

  const [bnbAmount, setBnbAmount] = useState("0");

  const [polygonAmount, setPolygonAmount] = useState("0");

  const [payMeButtonContent, setPayMeButtonContent] = useState("");

  const [manualTransNetType, setManualTransNetType] = useState("ether");

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [onBoardingState, setOnBoardingState] = useState({
    run: localStorage.getItem("onBoarding_2") ? false : true,
    steps: [
      {
        content: <h4>Here is how the payment works</h4>,
        placement: "center",
        target: "body",
      },
      {
        target: "#tab-web3-pay-toggle",
        content: <p>This is my awesome feature! <a className="link" href="https://drive.google.com/file/d/1o-9FnJBlKCkg8-3jMKta-fsCQRxyB3eC/view" target="_blank">View Demo</a></p>,
        title: <h4>Option 1: Payment through Metamask/ Wallet Connect</h4>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        placement: "bottom",
      },
      {
        target: ".web3-button-connect",
        content: "Link your metamask/waller connect to complete the payment",
        title: <h4>Connect web3</h4>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        placement: "bottom",
      },
      {
        target: "#tab-static-pay-toggle",
        content: <p>Please click on the above Scan & Pay option to continue. <a className="link" href="https://drive.google.com/file/d/14Qi54zlvf_haiojFFsj62WxY49dNxcmv/view" target="_blank">View Demo</a></p>,
        title: <h4>Option 2: Scan & Pay</h4>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: "bottom",
        spotlightClicks: true,
      },
      {
        target: "#payment-toggler",
        content: "Select the Cryptocurrency",
        title: <h4>Choose Payment</h4>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        placement: "bottom",
      },
      {
        target: ".transaction-input",
        content: "On completion of payment mention the hash key in the below field",
        title: <h4>Transaction hash</h4>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        placement: "bottom",
      },
      {
        content: <h4>Simple and easy.</h4>,
        placement: "center",
        target: "body",
      },
    ],
    index: 0,
    isTabChanged: false,
  });

  const handleConnectWalletClose = () => setConnectWalletModalStatus(false);
  const handleConnectWalletOpen = () => setConnectWalletModalStatus(true);

  useEffect(() => {
    if (payMeButtonContent !== "") {
      window.onbeforeunload = function () {
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [payMeButtonContent]);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setLoadingBlockchain(false);
      setConnectMetaMask(true);
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setLoadingBlockchain(false);
      setConnectMetaMask(true);
      loadBlockchainData();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const findNet = async () => {
    switch (chainId) {
      case etherNetID:
        setMetamaskNetStatus("ether");
        return "ETH";
        break;
      case binanceNetID:
        setMetamaskNetStatus("bnb");
        return "BNB";
        break;
      case polygonNetID:
        setMetamaskNetStatus("polygon");
        return "MATIC";
        break;
      default:
        setMetamaskNetStatus("");
        return "";
        break;
    }
  };

  const setCryptoPayment = async () => {
    price
      .getBasePrice("USD", "ETH")
      .then((value) => {
        console.log("ether", value.price);
        setEtherAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
    price
      .getBasePrice("USD", "BNB")
      .then((value) => {
        console.log("bnb", value.price);
        setBnbAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
    price
      .getBasePrice("USD", "MATIC")
      .then((value) => {
        console.log("matic", value.price);
        setPolygonAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    //setAccount(accounts[0]);

    const ethBalance = await web3.eth.getBalance(accounts[0]);
    setEthBalance(ethBalance);

    findNet().then((val) => setCrytoSymbol(val));
    setLoading(false);
  };

  const checkMetaMaskNetwork = async (value) => {
    // try {
    //   let web3 = window.web3;
    //   const networkId = await web3.eth.net.getId();
    //   console.log("Network Id", networkId);
    //   if (value == "ether" && networkId == etherNetID) {
    //     // network is connected on Ropsten or Ether network.
    //     setMetamaskNetStatus("ether");
    //     console.log("Ether");
    //   } else if (value == "bnb" && networkId == binanceNetID) {
    //     setMetamaskNetStatus("bnb");
    //     console.log("bnb");
    //   } else if (value == "polygon" && networkId == polygonNetID) {
    //     setMetamaskNetStatus("polygon");
    //     console.log("polygon");
    //   } else {
    //     window.alert(
    //       "Please change the network into " +
    //         value +
    //         ". Once you changed the network in metamask refresh the page."
    //     );
    //     setMetamaskNetStatus("");
    //   }
    // } catch (error) {
    //   const notificationMessage = getErrorNotificationMessage(
    //     "Please try again..."
    //   );
    //   props.dispatch(createNotification(notificationMessage));
    //   console.log("Payment is not done..", error);
    //   setPayMeButtonContent("");
    //   return;
    // }
    hanldeLogout();
    setAuth({
      ...auth,
      chainId: Number(value),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let web3 = window.web3;

      let response;
      setPayMeButtonContent("Initied. Please Wait...");

      let account = auth.accounts;

      if (account != "") {
        // Send ether to admin account.
        if (metamaskNetStatus == "ether" && chainId == etherNetID) {
          let paymentAmount =
            etherAmount * Number(props.paymentInfo.data.token_creation_charge);
          paymentAmount = paymentAmount.toString();
          response = await web3.eth.sendTransaction({
            from: account,
            to: "0x57df1D419DF8fF508e2979bA551977487a064608",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
            gasLimit: 21000,
            gasPrice: 20000000000,
          });
        } else if (metamaskNetStatus == "bnb" && chainId == binanceNetID) {
          let paymentAmount =
            bnbAmount * Number(props.paymentInfo.data.token_creation_charge);
          paymentAmount = paymentAmount.toString();
          response = await web3.eth.sendTransaction({
            from: account,
            to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
            gasLimit: 21000,
            gasPrice: 20000000000,
          });
        } else if (metamaskNetStatus == "polygon" && chainId == polygonNetID) {
          let paymentAmount =
            polygonAmount *
            Number(props.paymentInfo.data.token_creation_charge);
          paymentAmount = paymentAmount.toString();
          response = await web3.eth.sendTransaction({
            from: account,
            to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
            gasLimit: 21000,
            gasPrice: 20000000000,
          });
          console.log("response", response);
        } else {
          window.alert(
            "Please change the network into " +
              metamaskNetStatus +
              ". Once you changed the network in metamask refresh the page."
          );
          return;
        }
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Please choose the network type..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
        return;
      }
      if (response !== null) {
        // Save the transaction details.
        console.log("Payment received..");
        props.dispatch(
          saveTokenPaymentAutoStart({
            crypto_token_id:
              props.tokenDetails.data.crypto_token.crypto_token_id,
            from_wallet_address: account,
            payment_id: response.transactionHash,
            payment_mode: metamaskNetStatus,
            network_type: metamaskNetStatus,
            payment_type: "auto",
          })
        );
        setPaymentCompleted(true);
        setPayMeButtonContent("");
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Payment Failed. Please try again..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
      }
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Payment Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      console.log("Payment is not done..", error);
      setPayMeButtonContent("");
    }
  };

  const createTokenschema = Yup.object().shape({
    transactionHash: Yup.string().required("Transaction Hash is required"),
  });

  const manualTransaction = (values) => {
    props.dispatch(
      saveTokenPaymentAutoStart({
        crypto_token_id: props.tokenDetails.data.crypto_token.crypto_token_id,
        payment_id: values.transactionHash,
        payment_mode: manualTransNetType,
        payment_type: "manual",
      })
    );
  };

  useEffect(() => {
    if (chainId && account) {
      findNet().then((val) => setCrytoSymbol(val));
    }
  }, [chainId]);

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    localStorage.setItem("onBoarding_2", false);
    if (finishedStatuses.includes(status)) {
      setOnBoardingState({ ...onBoardingState, run: false });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (action === ACTIONS.NEXT && onBoardingState.index != 4) {
        setOnBoardingState((prev) => {
          return {
            ...onBoardingState,
            index: onBoardingState.index + 1,
          };
        });
      }

      if (onBoardingState.index == 4 && onBoardingState.isTabChanged) {
        setOnBoardingState({
          ...onBoardingState,
          isTabChanged: false,
        });

        setTimeout(() => {
          setOnBoardingState({
            ...onBoardingState,
            run: true,
            index: 4,
            isTabChanged: false,
          });
        }, 400);
      }

      if (onBoardingState.index == 4 && !onBoardingState.isTabChanged) {
        setOnBoardingState({
          ...onBoardingState,
          index: 5,
          isTabChanged: false,
        });
      }
    }
  };

  const changeTabForOnBoard = () => {
    setOnBoardingState((prev) => {
      return {
        ...onBoardingState,
        run: false,
        isTabChanged: true,
        index: 4,
      };
    });
  };

  useEffect(() => {
    if (account) {
      handleConnectWalletClose();
    }
  }, [account]);

  return (
    <>
      {props.tokenDetails.loading ? (
        "Loading..."
      ) : props.tokenDetails.data.crypto_token.payment_status == "1" ? (
        <>
          Payment is completed we are redirecting to deploy contract page..
          <Redirect
            to={`/tokens/deploy-token/${props.tokenDetails.data.crypto_token.crypto_token_unique_id}`}
          />
        </>
      ) : (
        <section className="landing-other-pages">
          <Joyride
            callback={handleJoyrideCallback}
            continuous={true}
            //getHelpers={StoreHelpers}
            run={onBoardingState.run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={onBoardingState.steps}
            stepIndex={onBoardingState.index}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
          />
          <div className="cretae-token-confirmation-landing">
            <div className="container">
              <div className="custom-box-landing p-3 p-lg-4 create-token-width custom-shadow">
                <div className="row no-gutters">
                  <div class="col-lg-12">
                    <ul class="nav tab-nav tab-nav-s2 tab-nav-center mb-3">
                      <li>
                        <a
                          class="active"
                          data-toggle="tab"
                          href="#tab-web3-pay"
                          id="tab-web3-pay-toggle"
                        >
                          Metamask/walletconnect
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#tab-static-pay"
                          id="tab-static-pay-toggle"
                          onClick={() => changeTabForOnBoard()}
                        >
                          Scan&Pay
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane fade active show" id="tab-web3-pay">
                        <div className="col-md-12 align-items-center d-flex justify-content-center">
                          {props.paymentInfo.loading ? (
                            "Loading..."
                          ) : (
                            <div className="admin-amount-wrapper">
                              <div
                                style={{ color: "black", marginBottom: "20px" }}
                              >
                                NOTE: Please connect your Metamask with the
                                appropriate network and make the payment to
                                complete your token generation
                              </div>

                              <div>
                                <h4>
                                  {" "}
                                  <span className="black-text text-bold">
                                    Pay Amount
                                  </span>{" "}
                                  :{" "}
                                  {props.paymentInfo.data.token_creation_charge}{" "}
                                  USD
                                </h4>

                                <h5 className="black-text">
                                  {loading ? (
                                    ""
                                  ) : (
                                    <>
                                      Balance :{" "}
                                      {window.web3.utils.fromWei(
                                        ethBalance,
                                        "Ether"
                                      )}{" "}
                                      {crytoSymbol}
                                    </>
                                  )}
                                </h5>
                              </div>
                              <p className="text-bold my-4 ">
                                <span className="invalid-feedback d-inline">
                                  Note :
                                </span>{" "}
                                We accept ETH,BNB,Polygon.
                              </p>
                              <ul className="list list-check list-check-s3 list-check-s3-sm w-100 my-4">
                                <li className="text-bold mb-3">
                                  <p className="">
                                    Ether :{" "}
                                    {etherAmount *
                                      Number(
                                        props.paymentInfo.data
                                          .token_creation_charge
                                      )}{" "}
                                    Ether <i class="fab fa-ethereum"></i>
                                  </p>
                                </li>
                                <li className="text-bold mb-3">
                                  <p className="">
                                    Polygon :{" "}
                                    {polygonAmount *
                                      Number(
                                        props.paymentInfo.data
                                          .token_creation_charge
                                      )}{" "}
                                    Matic <i class="fab fa-ethereum"></i>
                                  </p>
                                </li>
                                <li className="text-bold mb-3">
                                  <p className="">
                                    Binance :{" "}
                                    {bnbAmount *
                                      Number(
                                        props.paymentInfo.data
                                          .token_creation_charge
                                      )}{" "}
                                    BNB <i class="fab fa-bitcoin"></i>
                                  </p>
                                </li>
                                {auth.authStatus && (
                                  <>
                                    <li className="text-bold mb-3">
                                      <p className="whitecolor">
                                        Wallet Address : {auth.accounts}
                                      </p>
                                    </li>
                                    <li className="text-bold mb-3">
                                      <p className="whitecolor">
                                        Balance :{" "}
                                        {Number(auth.ethBalance).toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 3 }
                                        )}{" "}
                                        {
                                          CHAIN_INFO[auth.chainId]
                                            .nativeCurrency.symbol
                                        }
                                      </p>
                                    </li>
                                  </>
                                )}
                              </ul>
                              <RadioGroup
                                aria-label="coin"
                                name="coin"
                                row
                                value={auth.chainId}
                                onChange={(event) => {
                                  // if (connectMetaMask)
                                  //   checkMetaMaskNetwork(event.target.value);
                                  // else {
                                  //   const notificationMessage =
                                  //     getErrorNotificationMessage(
                                  //       "Connect metamask first..."
                                  //     );
                                  //   props.dispatch(
                                  //     createNotification(notificationMessage)
                                  //   );
                                  // }
                                  checkMetaMaskNetwork(event.target.value);
                                }}
                              >
                                {/* <FormControlLabel
                                  value="ether"
                                  control={<Radio color="primary" />}
                                  label="Ethereum"
                                />
                                <FormControlLabel
                                  value="polygon"
                                  control={<Radio color="primary" />}
                                  label="Polygon"
                                />
                                <FormControlLabel
                                  value="bnb"
                                  control={<Radio color="primary" />}
                                  label="BNB"
                                /> */}
                                {Object.entries(SupportedChainId).map(
                                  (chain, index) => (
                                    <>
                                      <FormControlLabel
                                        value={
                                          Object.entries(SupportedChainId)[
                                            index
                                          ][1]
                                        }
                                        control={<Radio color="primary" />}
                                        label={
                                          Object.entries(SupportedChainId)[
                                            index
                                          ][0]
                                        }
                                        key={index}
                                      />
                                    </>
                                  )
                                )}
                              </RadioGroup>

                              <div className="my-3 my-lg-4">
                                {active === false && chainId == undefined ? (
                                  <div className="buttons-wrapper">
                                    <button
                                      type="submit"
                                      className="btn btn-primary withTheme web3-button-connect"
                                      //onClick={loadWeb3}
                                      onClick={() => handleConnectWalletOpen()}
                                    >
                                      Connect Wallet
                                    </button>
                                  </div>
                                ) : paymentCompleted === false ? (
                                  <div className="buttons-wrapper">
                                    <button
                                      type="submit"
                                      className="btn btn-primary withTheme web3-button-connect"
                                      onClick={handleSubmit}
                                      disabled={
                                        payMeButtonContent !== "" ? true : false
                                      }
                                    >
                                      {payMeButtonContent !== ""
                                        ? payMeButtonContent
                                        : "Click here to pay"}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger withTheme web3-button-connect"
                                      onClick={hanldeLogout}
                                    >
                                      Logout
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="tab-pane fade " id="tab-static-pay">
                        <div className="col-md-12">
                          <div class="container">
                            <div class="row justify-content-center">
                              <div
                                style={{ color: "black", marginBottom: "25px" }}
                              >
                                NOTE: Please select the Appropriate Wallet
                                address to make the payment and Update your
                                transaction Hash Below for our team to start the
                                token deployment
                              </div>

                              <div class="col-lg-12">
                                <ul
                                  class="nav tab-nav tab-nav-s2 tab-nav-center mb-3"
                                  id="payment-toggler"
                                >
                                  <li>
                                    <a
                                      class="active"
                                      data-toggle="tab"
                                      href="#tab-eth"
                                    >
                                      Ethereum
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-btc"
                                      class=""
                                    >
                                      BTC
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-ripple"
                                      class=""
                                    >
                                      Ripple
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-usdc"
                                      class=""
                                    >
                                      USDC
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-usdt"
                                      class=""
                                    >
                                      USDT
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-xlm"
                                      class=""
                                    >
                                      XLM
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#tab-tron"
                                      class=""
                                    >
                                      TRON
                                    </a>
                                  </li>
                                  {/* <li>
                              <a data-toggle="tab" href="#tab-bnb" class="">
                                BNB
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#tab-polygon" class="">
                                Polygon
                              </a>
                            </li> */}
                                </ul>
                                {props.paymentInfo.loading ? (
                                  "Loading..."
                                ) : (
                                  // ETH STARt
                                  <div class="tab-content">
                                    <div
                                      class="tab-pane fade active show"
                                      id="tab-eth"
                                    >
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          Etherum Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .ether_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .ether_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* ETHERUM END */}

                                    {/* Ribble Start */}
                                    <div class="tab-pane fade" id="tab-ripple">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          Ripple Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .ripple_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .ripple_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* Ribble END */}

                                    {/* USDC Start */}
                                    <div class="tab-pane fade" id="tab-usdc">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          USDC Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .usdc_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .usdc_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* USDC END */}

                                    {/* USDT Start */}
                                    <div class="tab-pane fade" id="tab-usdt">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          USDT Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .usdt_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .usdt_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* USDC END */}

                                    {/* XLM Start */}
                                    <div class="tab-pane fade" id="tab-xlm">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          XLM Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .xlm_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .xlm_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* XLM END */}

                                    {/* TRON Start */}
                                    <div class="tab-pane fade" id="tab-tron">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          TRON Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .tron_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .tron_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* TRON END */}

                                    {/* BNB Start */}
                                    <div class="tab-pane fade" id="tab-bnb">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          BNB Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .binance_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .binance_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* BNB END */}

                                    {/* POLYGON START END */}
                                    <div class="tab-pane fade" id="tab-polygon">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          Polygon Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .polygon_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .polygon_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* POLYGOn END */}

                                    {/* BTC START */}
                                    <div class="tab-pane fade" id="tab-btc">
                                      <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h3 class="title title-md">
                                          BTC Wallet Address
                                        </h3>
                                        <h6 className="black-text">
                                          {
                                            props.paymentInfo.data
                                              .bitcoin_wallet_address
                                          }
                                        </h6>
                                        <div>
                                          <img
                                            src={
                                              props.paymentInfo.data
                                                .bitcoin_wallet_qr_code
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* BTC END */}
                                  </div>
                                )}
                              </div>
                            </div>
                            <Formik
                              initialValues={{
                                transactionHash: "",
                              }}
                              className="w-100"
                              validationSchema={createTokenschema}
                              onSubmit={(values) => manualTransaction(values)}
                            >
                              {({ errors, touched, setFieldValue }) => (
                                <Form noValidate>
                                  <div className="container-fluid">
                                    <div className="custom-box-landing p-0 create-token-width">
                                      <div className="createToken-header">
                                        <h5 className="text-bold m-0 whitecolor">
                                          Enter the transaction hash
                                        </h5>
                                      </div>
                                      <br />
                                      <div>
                                        <h4 className="text-bold m-0">
                                          {" "}
                                          Pay Amount :{" "}
                                          {
                                            props.paymentInfo.data
                                              .token_creation_charge_with_gas_fee
                                          }{" "}
                                          USD
                                        </h4>
                                      </div>
                                      <div className="form-wrapper p-3">
                                        <div className="row">
                                          <div class="form-group col-lg-12">
                                            <h5 className="black-text">
                                              Transaction Hash{" "}
                                              <span class="text-danger">*</span>
                                            </h5>
                                            <div class="controls">
                                              <Field
                                                type="text"
                                                name="transactionHash"
                                                placeholder="Transaction Hash "
                                                className={`input-bordered transaction-input ${
                                                  touched.transactionHash &&
                                                  errors.transactionHash
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                component="div"
                                                name="transactionHash"
                                                className="invalid-feedback mt-3"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 p-0">
                                        <div className="d-flex justify-content-center my-3">
                                          <button
                                            type="submit"
                                            className="btn btn-md btn-medium btn-grad no-change p-3 mx-auto d-block"
                                            disabled={
                                              props.saveTokenPayAuto
                                                .buttonDisable
                                            }
                                          >
                                            {props.saveTokenPayAuto
                                              .loadingButtonContent !== null
                                              ? props.saveTokenPayAuto
                                                  .loadingButtonContent
                                              : "Submit"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 align-items-center d-flex justify-content-center my-3 my-md-0">
                <div className="admin-amount-wrapper">
                  <div>
                    <h4>
                      {" "}
                      <span className="black-text text-bold">Pay Amount</span> :
                      100USD
                    </h4>
                    <h5 className="black-text">Gas Fee : 0.05ETH</h5>
                    <h5 className="black-text">
                      Transaction Fee : 0.000015ETH
                    </h5>
                  </div>
                  <p className="text-bold my-4">
                    <span className="invalid-feedback d-inline">Note :</span> We
                    accept ETH,BNB,Polygon,BTC.
                  </p>
                  <ul className="list list-check list-check-s3 list-check-s3-sm w-100 my-4">
                    <li className="text-bold mb-3">
                      <p>
                        Ether : 150 Ether <i class="fab fa-ethereum"></i>
                      </p>
                    </li>
                    <li className="text-bold mb-3">
                      <p>
                        BTC : 0.001 BTC <i class="fab fa-bitcoin"></i>
                      </p>
                    </li>
                  </ul>
                  <RadioGroup aria-label="coin" name="coin" row >
                    <FormControlLabel
                      value="ether"
                      control={<Radio color="primary" />}
                      label="Ethereum"
                    />
                    <FormControlLabel
                      value="polygon"
                      control={<Radio color="primary" />}
                      label="Polygon"
                    />
                    <FormControlLabel
                      value="btc"
                      control={<Radio color="primary" />}
                      label="bitcoin"
                    />
                    <FormControlLabel
                      value="bnb"
                      control={<Radio color="primary" />}
                      label="BNB"
                    />
                  </RadioGroup>
                  <div className="my-3 my-lg-4">
                    <button
                      type="submit"
                      className="btn btn-md btn-medium btn-grad no-change p-3 mx-auto d-block"
                    >
                      Click here to pay
                    </button>
                  </div>
                </div>
              </div>
            */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {connectWalletModalStatus && (
        <ConnectWalletModal
          status={connectWalletModalStatus}
          handleConnectWalletClose={handleConnectWalletClose}
        />
      )}
    </>
  );
};
const mapStateToPros = (state) => ({
  tokenDetails: state.token.tokenDetails,
  paymentInfo: state.token.paymentInfo,
  saveTokenPayAuto: state.token.saveTokenPayAuto,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CreateTokenAdminComission);
