import React from "react";
import { Link } from "react-router-dom";
import SvgIcon from "../../SvgIcons/SvgIcon";
import configuration from "react-global-configuration";

const NotFoundIndex = () => {
  return (
    <>
      <section
        class="h-p100 bg-img error-page  error-page-bg"
      >
        <div class="container h-100">
          <div class="row h-100 align-items-center justify-content-md-center">
            <div class="col-lg-6 col-12">
              <div class="box box-body rounded bg-transparent text-center text-white b-1 pb-50 border-white">
                <h2 class="headline text-warning"> 404</h2>

                <h3 class="margin-top-0">
                  <i class="fa fa-warning text-warning"></i> PAGE NOT FOUND !
                </h3>

                <p>YOU SEEM TO BE TRYING TO FIND HIS WAY HOME</p>
                <div class="text-center">
                  <Link
                    to="/"
                    class="btn rounded btn-warning margin-top-10"
                  >
                    Back to dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundIndex;
