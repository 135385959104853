export let SupportedChainId = {
  // ETHEREUM: 1,
  POLYGON: 80001,
  BINANCE: 97,
  VELAS: 111,
};

export const All_supported_ChainIds = Object.values(SupportedChainId).filter(
  (chains) => typeof chains === "number"
);

export const SUPPORTED_RPC_URLS = {
  [SupportedChainId.ETHEREUM]: "https://mainnet.infura.io/v3/f1c8f519fca8496080b01af696be43b6",
  [SupportedChainId.POLYGON]: `https://rpc-mumbai.maticvigil.com/`,
  [SupportedChainId.BINANCE]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  [SupportedChainId.VELAS]: `https://evmexplorer.testnet.velas.com/rpc`,
};

export const CHAIN_INFO = {
  [SupportedChainId.ETHEREUM]: {
    explorer: "https://etherscan.io",
    label: "Ethereum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  },
  [SupportedChainId.POLYGON]: {
    explorer: "https://mumbai.polygonscan.com",
    label: "Polygon",
    nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
  },
  [SupportedChainId.BINANCE]: {
    explorer: "https://testnet.bscscan.com",
    label: "Binance",
    nativeCurrency: { name: "Binance", symbol: "BNB", decimals: 18 },
  },
  [SupportedChainId.VELAS]: {
    explorer: "https://evmexplorer.velas.com",
    label: "Velas",
    nativeCurrency: { name: "Velas", symbol: "VLX", decimals: 18 },
  },
};

export const SELECTED_CHAIN_INFO = (chainId) => CHAIN_INFO[chainId];
