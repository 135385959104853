import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const DashboardLandingLayout = (props) => {
  return (
    <>
      <Helmet>
      <script
          type="text/javascript"
          src={window.location.origin + "/assets/dashboard-landing/js/scripts.js"}
        ></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.10.3/jquery-ui.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.8/js/lightgallery.min.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/dashboard-landing/js/smoothscroll.min.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/dashboard-landing/js/appear.min.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/dashboard-landing/js/animations.min.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/dashboard-landing/js/custom.js"}
        ></script>
        <link href="https://fonts.googleapis.com/css?family=Cutive+Mono&display=swap" rel="stylesheet"/>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/dashboard-landing/stylesheets/base.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/dashboard-landing/stylesheets/skeleton.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/dashboard-landing/stylesheets/main.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/dashboard-landing/stylesheets/layout.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          media="all"
          href={window.location.origin + "/assets/dashboard-landing/css/animations.min.css"}
        ></link>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/dashboard-landing/css/style.css"}
          ></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.8/css/lightgallery.min.css" />

      </Helmet>
      {React.cloneElement(props.children)}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(DashboardLandingLayout);
