import React from 'react'

const CurrencyExchange = () => {
  return (
    <>
        <div className="content-wrapper">
    <section className="content-header">
      <h1>
        Currency Exchange
      </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
        <li className="breadcrumb-item active">Currency Exchange</li>
      </ol>
    </section>

    <section className="content">
	  	<h2 className="page-header text-center text-yellow no-border">Buy and sell Coins at the<br/> Crypto without additional fees</h2>
		<h5 className="subtitle text-center text-white">Buy now and get +30% extra bonus Minimum pre-sale<br /> amount 50 Crypto Coin. We accept BTC crypto-currency.</h5>
	  	<div className="row">
			<div className="col-12">
				<div className="exchange-calculator text-center mt-35">
					<input type="text" className="form-control" name="coins-exchange" placeholder="" value="10.1548" />                
					<select className="coins-exchange" name="state">
					   <option value="BTC">BTC</option>
					   <option value="BTC">Ethereum</option>
					   <option value="Ripple">Ripple</option>
					   <option value="Ripple">Bitcoin Cash</option>
					   <option value="Ripple">Cardano</option>
					   <option value="Ripple">Litecoin</option>
					   <option value="Ripple">NEO</option>
					   <option value="Ripple">Stellar</option>
					   <option value="Ripple">EOS</option>
					   <option value="Ripple">NEM</option>
					</select>
					<div className="equal"> = </div>
					<input type="text" className="form-control" name="money-exchange" placeholder="" value="125.158" />                
					<select className="money-exchange" name="state">
					   <option value="USD">USD</option>
					   <option value="EURO">EURO</option>
					</select>
			   </div>
			   <div className="text-center mt-15">
					<a href="#" className="btn btn-warning mx-auto">EXCHANGE NOW</a>
			   </div>
			</div>
		</div>
		<div className="row mt-30">
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc BTC mr-5" title="BTC"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Bitcoin BTC</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">2.340000434 <span className="text-gray">BTC</span> <span className="text-info">$0.04</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-success">+5.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc LTC mr-5" title="LTC"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Litecoin LTC</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">1.34000434 <span className="text-gray">LTC</span> <span className="text-info">$0.14</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-danger">-2.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc NEO mr-5" title="NEO"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Neo NEO</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">0.30000434 <span className="text-gray">NEO</span> <span className="text-info">$5.04</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-success">+4.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc DASH mr-5" title="DASH"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Dash DASH</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">0.34000434 <span className="text-gray">DASH</span> <span className="text-info">$0.54</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-danger">-5.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc ETH mr-5" title="ETH"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Ethereum ETH</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">0.12300434 <span className="text-gray">ETH</span> <span className="text-info">$0.12</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-success">+0.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>
		  <div className="col-12 col-md-6 col-lg-4">
            <div className="box box-body">
              <div className="media align-items-center p-0">
                <div className="text-center">
				  <a href="#"><i className="cc XRP mr-5" title="XRP"></i></a>
			  	</div>
			  	<div>
				  <h3 className="no-margin">Ripple XRP</h3>
			  	</div>
              </div>
              <div className="flexbox align-items-center mt-25">
                <div>
				  <p className="no-margin">0.00000434 <span className="text-gray">XRP</span> <span className="text-info">$0.04</span></p>
			  	</div>
			  	<div className="text-right">
				  <p className="no-margin"><span className="text-danger">-1.35%</span></p>
			 	</div>
              </div>
            </div>			
          </div>

		</div>
    </section>
  </div>
    </>
  )
}

export default CurrencyExchange
