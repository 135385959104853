import React from 'react'

const IcoRoadMap = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Roadmap/Timeline
          </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
            <li className="breadcrumb-item"><a href="#">Initial Coin Offering</a></li>
            <li className="breadcrumb-item active">Roadmap/Timeline</li>
          </ol>
        </section>

        <section className="content">
        <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            <span className="text-bold">MILESTONES</span><br />
            <p className="font-size-18 mb-0">Minimum purchase is 50 Coins tokens. Get a bonus from 5% to 25% on every token purchase</p>
          </h1>
        </div>		  
        </div>
        <div className="row">
            <div className="col-md-12">
              <ul className="timeline">
                <li className="time-label">
                      <span className="bg-yellow">
                        15 Jan. 2017
                      </span>
                </li>
                <li>
                  <i className="cc BTC bg-dark text-yellow"></i>

                  <div className="timeline-item d-inline-block">
                    <span className="time"><i className="fa fa-clock-o"></i> 11:48</span>

                    <h3 className="timeline-header font-size-24">Pre-ICO</h3>

                    <div className="timeline-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </div>
                    <div className="timeline-footer text-left">
                      <a href="#" className="btn btn-dark btn-sm">Read more</a>
                    </div>
                  </div>
                </li>
                <li>
                  <i className="cc DASH bg-dark text-yellow"></i>

                  <div className="timeline-item d-inline-block">
                    <span className="time"><i className="fa fa-clock-o"></i> 11 mins ago</span>

                    <h3 className="timeline-header font-size-24">Crypto ICO</h3>

                    <div className="timeline-body">
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </div>
                    <div className="timeline-footer text-left">
                      <a href="#" className="btn btn-dark btn-sm">Read more</a>
                    </div>
                  </div>
                </li>
                <li>
                  <i className="cc EMC-alt bg-dark text-yellow"></i>

                  <div className="timeline-item d-inline-block">
                    <span className="time"><i className="fa fa-clock-o"></i> 55 mins ago</span>

                    <h3 className="timeline-header font-size-24">CHP launch on Crypto</h3>

                    <div className="timeline-body">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                    </div>
                    <div className="timeline-footer text-left">
                      <a href="#" className="btn btn-dark btn-sm">Read more</a>
                    </div>
                  </div>
                </li>
                <li className="time-label">
                      <span className="bg-yellow">
                        15 Nov. 2016
                      </span>
                </li>
                <li>
                  <i className="cc HEAT bg-dark text-yellow"></i>

                  <div className="timeline-item d-inline-block">
                    <span className="time"><i className="fa fa-clock-o"></i> 8 days ago</span>

                    <h3 className="timeline-header font-size-24">Crypto Listed in Exchanges</h3>

                    <div className="timeline-body">
                      Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.
                    </div>
                    <div className="timeline-footer text-left">
                      <a href="#" className="btn btn-dark btn-sm">Read more</a>
                    </div>
                  </div>
                </li>
                <li>
                  <i className="cc KMD bg-dark text-yellow"></i>

                  <div className="timeline-item d-inline-block">
                    <span className="time"><i className="fa fa-clock-o"></i> 18 days ago</span>

                    <h3 className="timeline-header font-size-24">Mobile Application</h3>

                    <div className="timeline-body">
                      There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                    </div>
                    <div className="timeline-footer text-left">
                      <a href="#" className="btn btn-dark btn-sm">Read more</a>
                    </div>
                  </div>
                </li>
                <li>
                  <i className="fa fa-clock-o bg-yellow text-white"></i>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default IcoRoadMap
