import React, { useState, useEffect } from "react";


const BusinessLandingIndex = () => {
    return (
        <>
            {/* <body className="home page-template-default page template-slider color-custom style-default button-round layout-full-width if-overlay if-border-hide no-content-padding header-transparent header-fw minimalist-header sticky-header sticky-tb-color ab-hide subheader-both-left menu-link-color menuo-right menuo-no-borders mobile-tb-center mobile-side-slide mobile-mini-mr-lc tablet-sticky mobile-header-mini mobile-sticky be-reg-2173 elementor-default elementor-kit-33 elementor-page elementor-page-9"> */}
                <body>
                <div className="business-banner-sec">
                    <div className="header-sec">
                        <a href="/" className="business-logo">
                            <img src={window.location.origin + "/assets/business-landing/img/Logo.png"} />
                        </a>
                    </div>
                    <div className="business-banner-content">
                        <h3>We help you to</h3>
                        <h2>Create your own Crypto Token</h2>
                        <p>
                            <span>&amp; also help you sell it to millions of users. By listing your <br />
                                token in an exchange and increasing the Market Cap.</span>
                            <br/>
                        </p>
                        <a href="javascript:void(0)" alt="ico dashboard" className="learn-more-btn">Learn more</a>
                    </div>
                    <div className="have-question-sec">
                        <div className="have-question-icon-sec">
                            <img src={window.location.origin + "/assets/business-landing/img/agency4-home-splash-icon.png"} />
                        </div>
                        <div className="have-question-info">
                            <h4>Have question?</h4>
                            <h5>hello@tokencreator.space</h5>
                        </div>
                    </div>
                </div>
                <div id="Content">
                    <div className="content_wrapper clearfix">
                        <div className="sections_group">
                            <div className="entry-content">
                                <div className="section mcb-section mcb-section-jlkrho8it equal-height-wrap" style={{ paddingTop: "120px", paddingBottom: "80px", backgroundImage: "url(/assets/business-landing/img/corporation3-sectionbg2.png)", backgroundRepeat: "no-repeat", backgroundPosition: "center top" }}>
                                    <div className="section_wrapper mcb-section-inner">
                                        <div className="wrap mcb-wrap 'mcb-wrap-dii57ylyb two-third valign-middle clearfix" style={{ height: "395px;" }}>
                                            <div className="mcb-wrap-inner-no">
                                                <div className="column_attr clearfix" style={{ padding: "40px 0 0" }}>
                                                    <h2>In this , Combo
                                                        <br />
                                                        <span className="themecolor">power pack</span> you get <br />these 3 Items</h2>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                    <ul className="list_check">
                                                        <li>Your own shiny Crypto Coin</li>
                                                        <li>Integrated in an intelligent Token Dashboard ( <a href="https://tokencreator.space/dashboard" target="_blank" alt="learnmore">learn more</a> )</li>
                                                        <li>Listed in a famous global Exchange</li>
                                                    </ul>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                    <h6><a href="#works" className="rev_btn">READ MORE </a></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap 'mcb-wrap-dii57ylyb one-third valign-middle clearfix" style={{ height: "395px" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-fhdesck3h one column_column">
                                                    <div className="column mcb-column mcb-item-fhdesck3h one-second.mcb-wrap">
                                                        <img src={window.location.origin + "/assets/business-landing/img/creating-a-cryptocurrency.png"} width="300" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section mcb-section mcb-section-0mqpzyi8q" id="works" style={{ paddingTop: "140px", paddingBottom: "100px", backgroundColor: "#251117", backgroundImage: "url(images/corporation3-sectionbg1.png)", backgroundRepeat: "no-repeat", backgroundPosition: "center top" }}>
                                    <div className="section_wrapper mcb-section-inner">
                                        <div className="wrap mcb-wrap mcb-wrap-evx8hnqnw one valign-top clearfix">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-bv38v6b7i one column_column">
                                                    <div className="column_attr clearfix mobile_align_center">
                                                        <h2 style={{ color: "#fff" }}>How it works?</h2>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-uugbm1iqh one column_divider">
                                                    <hr className="no_line" style={{ margin: "0 auto 50px" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-pnqntd8mg one-fourth valign-top clearfix">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-cng7xd1fk one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                                        <div className="image_wrapper">
                                                            <img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-icon1.png"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-en5krzc6x one column_column">
                                                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                                                        <h5 style={{ color: "#fff" }}>We create your Crypto Coin as per your specifications.<br /><br />Deposit them in your
                                                            Crypto Wallet.
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-9oj75bukn one-fourth valign-top clearfix">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-njbv27oxx one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                                        <div className="image_wrapper">
                                                            <img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-icon2.png"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-ixzswp634 one column_column">
                                                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                                                        <h5 style={{ color: "#fff" }}>We integrate your Coin in a sophisticated Token Dashboard.<br /><br />This automatic Dashboard is
                                                            really something. <a href="https://tokencreator.space/dashboard" target="_blank" className="themecolor">Check it out</a>.
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-sz4jxf7ew one-fourth valign-top clearfix">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-r0shst6zu one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                                        <div className="image_wrapper"><img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-icon3.png"} /> </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-mm7qqdxfa one column_column">
                                                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                                                        <h5 style={{ color: "#fff" }}>We list your Cryto Coin in a
                                                            famous global Exchange.<br /><br />That has millions of daily users &amp; billions in transaction.
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-kcgozt90f one-fourth valign-top clearfix">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-gd3jwrcib one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                                        <div className="image_wrapper"><img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-icon4.png"} /> </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-ak1y31y4t one column_column">
                                                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                                                        <h5 style={{ color: "#fff" }}>We connect your Token Dashboard
                                                            with the Exchange API's<br /><br />So, your Crypto coins Market Cap increases tremendously.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section mcb-section mcb-section-tok6e8f5e equal-height-wrap" style={{ paddingTop: "140px", paddingBottom: "70px" }}>
                                    <div className="section_wrapper mcb-section-inner">
                                        <div className="wrap mcb-wrap mcb-wrap-8wax3aabn two-fifth valign-middle clearfix" style={{ padding: "0px 5% 0px 0px", height: "544px" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-86ibn59rj one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid no_border">
                                                        <div className="image_wrapper">
                                                            <img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/12.png"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-bz0oqqrfe one column_column column-margin-20px">
                                                    <div className="column_attr clearfix">
                                                        <h2>Admin Panel
                                                        </h2>
                                                        <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                        <p> Comes with an incredible Admin panel to manage your Crypto Token sales, Investor relations, master wallet, Notifications, Users etc. Every feature in the platform can be turned to manual -or- automatic mode instantly. </p>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-tipwp66yr one column_button"> <a className="button button_size_2" target="_blank" href="https://tokencreator.space/dashboard"><span className="button_label">Read more</span></a> </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-332vnv4p4 three-fifth valign-middle clearfix" style={{ padding: "0px 5% 0px 0px", height: "544px" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-vswusyudf one column_slider">
                                                    <div className="content_slider flat hide-arrows">
                                                        <ul className="content_slider_ul">
                                                            <li className="content_slider_li_1"><img width="780" height="600" src={window.location.origin + "/assets/business-landing/img/Creating-a-cryptocurrency-M.png"} className="scale-with-grid wp-post-image" alt loading="lazy" /> </li>
                                                            <li className="content_slider_li_2"><img width="780" height="600" src={window.location.origin + "/assets/business-landing/img/Creating-a-cryptocurrency-W.png"} className="scale-with-grid wp-post-image" alt loading="lazy" /> </li>
                                                        </ul>
                                                        <div className="slider_pager slider_pagination"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section mcb-section mcb-section-25jkuwmlw equal-height-wrap corporation3-section-border" style={{ paddingTop: "110px", paddingBottom: "100px" }}>
                                    <div className="section_wrapper mcb-section-inner">
                                        <div className="wrap mcb-wrap mcb-wrap-rh8q7n69y two-third valign-middle clearfix" style={{ padding: "0px 8% 0px 0px", height: "524px" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-8maxdga80 one column_column column-margin-20px">
                                                    <div className="column_attr clearfix">
                                                        <h2>How does this
                                                            <span className="themecolor">Increase my Token sales?</span></h2>
                                                        <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                        <h4>The smartest way to increase your Crypto coin sales is by quickly ranking it on portals where Crypto buyers & investors check which new token to invest in.</h4>
                                                        <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                        <p> Millions of cryptocurrency buyers daily check 3rd party portals like CoinmarketCap, DexTools etc. to discover new tokens. The only way to get your Crypto coin automatically listed and ranked on these portals are by increasing on-chain transaction volume and market cap. The smart dashboard we provide, automatically does this for you!
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-txierdngk one column_button"> <a className="button button_size_2 button_theme" target="_blank" href="https://create-a-cryptocurrency.medium.com/how-to-increase-the-market-cap-of-your-cryptocurrency-coin-f304fc22f030"><span className="button_label">Read more</span></a> </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-5316l7f4v one-third valign-middle clearfix" style={{ height: "524px" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-b51vl2t4b one column_column">
                                                    <div className="column_attr clearfix" style={{ padding: "40px 40px 25px", border: "2px solid #55c593", borderRadius: "15px" }}>
                                                        <h5 style={{ marginBottom: "5px" }}>Happy customers</h5>
                                                        <h3>127+</h3>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-g0kvksymp one column_column">
                                                    <div className="column_attr clearfix" style={{ padding: "40px 40px 25px", border: "2px solid #55c593", borderRadius: "15px" }}>
                                                        <h5 style={{ marginBottom: "5px" }}>Transaction volume achieved,
                                                        </h5>
                                                        <h3>$500M+
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section mcb-section mcb-section-d3mqh1c7s" id="how-it-works" style={{ paddingTop: "120px", paddingBottom: "80px", backgroundColor: "#f3f5f6" }}>
                                    <div className="section_wrapper mcb-section-inner">
                                        <div className="wrap mcb-wrap mcb-wrap-kmlm0by9d one-second valign-top clearfix" style={{ padding: "0 1%" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-fkhx98jce one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid no_border">
                                                        <div className="image_wrapper"><img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-pic1.jpg"} /> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-15p42o8q4 one-second valign-top clearfix" style={{ padding: "0 1%" }}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-bb19cikpi one column_column">
                                                    <div className="column_attr clearfix">
                                                        <h2>We Help you <span className="themecolor">all along</span></h2>
                                                        <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                        <ul className="list_check">
                                                            <li> Step-by-Step guidance by experts. 24/7 support </li>
                                                            <li> All Setup / Installation done and handed over up &amp; running </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-xfzt9250y one-second column_placeholder">
                                                    <div className="placeholder"> &nbsp; </div>
                                                </div>
                                                <div className="column mcb-column mcb-item-x1dhat9g2 one-second column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid no_border">
                                                        <div className="image_wrapper"><img className="scale-with-grid" src={window.location.origin + "/assets/business-landing/img/corporation3-home-pic2.jpg"} /> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap mcb-wrap mcb-wrap-kdcinz7fj one valign-top move-up clearfix" style={{ marginTop: "-80px" }} data-tablet="no-up" data-mobile="no-up">
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column mcb-item-ixqgmrr49 one column_button">
                                                    <div className="button_align align_center"> <a className="button button_size_2 button_theme" target="_blank" href="https://www.messenger.com/t/104425381888590"><span className="button_label"> Contact us for more info </span></a> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fb_chat">
                        <a href="https://www.messenger.com/t/104425381888590" className="fb-msg-btn-chat" target="_blank" rel="nofollow"> Contact us on Facebook</a>
                    </div>
                    <div className="fb_chat twitter">
                        <a href="hello@tokencreator.space" className="fb-msg-btn-chat tweet"> <span>-or-</span>&nbsp;via. Email
                        </a>
                    </div>
                </div>
            </body>
        </>
    );
}

export default BusinessLandingIndex;