
import {
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS
} from '../actions/ActionConstant'

const initialState = {
  dashboardInfo: {
    data: {},
    loading: true,
    error: false,
  },
};

const DarshboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_DASHBOARD_START:
      return {
        ...state,
        dashboardInfo: {
          loading: true,
          error: false,
          data: {},
        },
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardInfo: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
}

export default DarshboardReducer;
