import React,{useState} from "react";
import { connect } from "react-redux";
import { forgotPasswordStart } from "../store/actions/UserAction";

const ResetPassword = (props) => {

  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});
  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(forgotPasswordStart(forgotPasswordInputData));
  };

  return (
    <>
      <div className="modal fade" id="reset-popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <a
              href="#"
              className="modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <em className="ti ti-close"></em>
            </a>
            <div className="ath-container m-0">
              <div className="ath-body">
                <h5 className="ath-heading title">
                  Reset <small className="tc-default">with your Email</small>
                </h5>
                <form action="#">
                  <div className="field-item">
                    <div className="field-wrap">
                      <input
                        name="email"
												placeholder="Username or Email"
												type="text"
                        className="input-bordered"
												onChange={(event) =>
													setForgotPasswordInputData({
														...forgotPasswordInputData,
														email: event.currentTarget.value,
													})
												}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-block btn-md" type="submit"
										onClick={handleForgotPassword}
										disabled={props.forgotPassword.buttonDisable}>
                    {props.forgotPassword.loadingButtonContent !== null
                    ? props.forgotPassword.loadingButtonContent
                    : "Forgot Password"}
                  </button>
                  <div className="ath-note text-center">
                    Remembered?{" "}
                    <a
                      href="#"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#login-popup"
                    >
                      {" "}
                      <strong>Sign in here</strong>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPassword);
