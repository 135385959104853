import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import notifyReducer from "react-redux-notify";
import ErrorReducer from "./ErrorReducer";
import NotificationReducer from "./NotificationReducer";
import PageReducer from "./PageReducer";
import VerificationDocumentReducer from "./VerificationDocumentReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import ProjectReducer from "./ProjectsReducer";
import CryptoWalletReducer from "./CryptoWalletReducer";
import FaqReducer from "./FaqReducer";
import TokenReducer from "./TokenReducer";
import DashboardReducer from './DashboardReducer'

export default combineReducers({
  users: UserReducer,
  errorDetails: ErrorReducer,
  notification: NotificationReducer,
  notifications: notifyReducer,
  subscriptions: SubscriptionReducer,
  docs: VerificationDocumentReducer,
  page: PageReducer,
  projectReducer: ProjectReducer,
  cryptoWallet: CryptoWalletReducer,
  faq: FaqReducer,
  token: TokenReducer,
  dashboard : DashboardReducer
});
