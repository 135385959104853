import React from 'react'

const TransactionSearch = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Search for: Transactions
          </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
            <li className="breadcrumb-item"><a href="#">Transactions</a></li>
            <li className="breadcrumb-item active">Transactions Search</li>
          </ol>
        </section>

        <section className="content">

        <div className="box">
            <div className="box-body">
          <h5><a href="#">2dxg25454841132457d21564d1321d35d5zxd54d545dv4v34d54v5d4v5d4x54d</a></h5>
          <div className="row justify-content-between">
            <div className="col-md-auto">
              <h5><a href="#">1c565646464x456464s664444vg4646z84df4v4654c6vdsf446x4cv8df</a>
                <i className="fa fa-long-arrow-right text-green px-15"></i>
                <a href="#">3df2dfds24478454d445d5sf454df545d</a></h5>
            </div>
            <div className="col-md-auto">
                <h5 className="text-bold text-right">0.35473875 BTC</h5>
            </div>
          </div>
          <div className="text-right mt-30">
            <span className="badge badge-xl badge-primary mr-5">45 Confirmation</span>
            <span className="badge badge-xl badge-success">1.124589756 BTC</span>
          </div>
            </div>
          </div>      
        
        <div className="row">
        <div className="col-lg-6 col-12">
          <div className="box">
          <div className="box-header with-border">
            <h3 className="box-title">Summary</h3>
          </div>
          <div className="box-body">
            <div className="table-responsive">
              <table className="table table-striped table-hover no-margin">
                            <tbody>
                              <tr>
                                <td>Size</td>
                                <td>125 (bytes)</td>
                              </tr>
                              <tr>
                                <td>Weight</td>
                                <td>1235</td>
                              </tr>
                              <tr>
                                <td>Received Time</td>
                                <td>2019-02-05 13:57:30</td>
                              </tr>
                              <tr>
                                <td>Lock Time</td>
                                <td>Block: 123456</td>
                              </tr>
                              <tr>
                                <td>Included In Blocks</td>
                                <td><a href="#"><span>1236458</span></a> ( 2018-02-05 14:01:53 + 4 minutes )</td>
                              </tr>
                              <tr>
                                <td>Confirmations</td>
                                <td>25 Confirmations</td>
                              </tr>
                              <tr>
                                <td>Visualize</td>
                                <td><a href="#"><span>View Tree Chart</span></a></td>
                              </tr>
                            </tbody>
                          </table>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="box">
          <div className="box-header with-border">
            <h3 className="box-title">Summary</h3>
          </div>
          <div className="box-body">
            <div className="table-responsive">
              <table className="table table-striped table-hover no-margin">
                <tbody>
                  <tr>
                  <td>Total Input</td>
                  <td>1.123456789 BTC</td>
                  </tr>
                  <tr>
                  <td>Total Output</td>
                  <td>0.123456789 BTC</td>
                  </tr>
                  <tr>
                  <td>Fees</td>
                  <td>0.987456321 BTC</td>
                  </tr>
                  <tr>
                  <td>Fee per byte</td>
                  <td>12,142.147 sat/B</td>
                  </tr>
                  <tr>
                  <td>Fee per weight unit</td>
                  <td>3,123.537 sat/WU</td>
                  </tr>
                  <tr>
                  <td>Estimated BTC Transacted</td>
                  <td>0.123456789 BTC</td>
                  </tr>
                  <tr>
                  <td>Visualize</td>
                  <td><a href="#"><span>Show scripts &amp; coinbase</span></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
        </div>

        </section>
      </div>
    </>
  )
}

export default TransactionSearch
