import React from "react";
import { connect } from "react-redux";
import { userLoginStart } from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";

const Login = (props) => {
  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  return (
    <>
      <div className="modal fade" id="login-popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <a
              href="#"
              className="modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <em className="ti ti-close"></em>
            </a>
            <div className="ath-container m-0">
              <div className="ath-body">
                <h5 className="ath-heading title">
                  Sign in{" "}
                  <small className="tc-default">
                    with your {configuration.get("configData.site_name")}{" "}
                    Account
                  </small>
                </h5>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form noValidate>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            type="email"
                            name="email"
                            className={`input-bordered ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Your Email"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback  mt-3"
                        />
                      </div>
                      <div className="field-item">
                        <div className="field-wrap">
                          <Field
                            name="password"
                            type="password"
                            className={`input-bordered ${
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Password"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                      <div className="field-item d-flex justify-content-between align-items-center">
                        {/* <div className="field-item pb-0">
                          <input
                            className="input-checkbox"
                            id="remember-me-100"
                            type="checkbox"
                          />
                          <label for="remember-me-100">Remember Me</label>
                        </div> */}
                        <div className="forget-link fz-6">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-dismiss="modal"
                            data-target="#reset-popup"
                          >
                            Forgot password?
                          </a>
                        </div>
                      </div>
                      <button
                        type="submit"
                        disabled={props.login.buttonDisable}
                        className="btn btn-primary btn-block btn-md"
                      >
                        {props.login.loadingButtonContent !== null
                          ? props.login.loadingButtonContent
                          : "Sign In"}
                      </button>
                    </Form>
                  )}
                </Formik>
                {/* <div className="sap-text">
                  <span>Or Sign In With</span>
                </div>

                <ul className="row gutter-20px gutter-vr-20px">
                  <li className="col">
                    <a href="#" className="btn btn-md btn-facebook btn-block">
                      <em className="icon fab fa-facebook-f"></em>
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li className="col">
                    <a href="#" className="btn btn-md btn-google btn-block">
                      <em className="icon fab fa-google"></em>
                      <span>Google</span>
                    </a>
                  </li>
                </ul> */}
                <div className="ath-note text-center">
                  Don’t have an account?{" "}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-dismiss="modal"
                    data-target="#register-popup"
                  >
                    {" "}
                    <strong>Sign up here</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Login);
